/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import DateUtils from 'helpers/DateUtils'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  TextField,
  CheckboxWithLabel,
  Select,
} from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import DialogTitle from 'components/Dialog/DialogTitle'

/* Type imports ------------------------------------------------------------- */
import type { DateSelectArg } from '@fullcalendar/core'
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  Planning,
  TypeRdv,
} from 'API/__generated__/Api'
import { TypeRdvType } from 'API/__generated__/Api'
import type { EventHandlerActionType } from 'types/EventHandler'

/* Type declarations -------------------------------------------------------- */
interface EventRequest extends Planning {
  day: string;
}

const eventSchema = Yup.object().shape<Shape<EventRequest>>({
  typeRDV: Yup.object().shape<Shape<TypeRdv>>({
    code: Yup.mixed<TypeRdvType>().required('Le type de RDV est obligatoire'),
  }).required(),
  day: Yup.string().nullable().required('La date de début est obligatoire'),
  dateDebut: Yup.string().nullable().required("L'heure de début est obligatoire"),
  dateFin: Yup.string().nullable().required("L'heure de fin est obligatoire"),
}).required()

type EventForm = FormikContextType<EventRequest>

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  align-items: end;
  justify-content: stretch;
`

const EntireDayContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  width: 100%;
  height: 56px;
  margin: 0;
  label {
    height: 100%;
  }
`

const CenteredCheckbox = styled(CheckboxWithLabel)`
  margin-top: -2px;
  margin-left: 15px;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningEventHandlerModalProps {
  handleClose: (type?: EventHandlerActionType | null, newEvent?: Planning) => void;
  action: EventHandlerActionType | null;
  event: Planning | undefined;
  dates: DateSelectArg | null;
  types: TypeRdv[];
}

const PlanningEventHandlerModal: React.FC<PlanningEventHandlerModalProps> = ({
  handleClose,
  action,
  event,
  dates,
  types,
}) => {
  const getDateWithDayAndTime = (time: string, day: string): string => DateUtils.dateToAPIStr(new Date(new Date(new Date(time).setDate(new Date(day).getDate())).setMonth(new Date(day).getMonth())))

  const onSubmit = (values: EventRequest): void => {
    const newEvent: Planning = {
      id: values.dateDebut + values.dateFin,
      ...event,
      dateDebut: getDateWithDayAndTime(values.dateDebut, values.day),
      dateFin: getDateWithDayAndTime(values.dateFin, values.day),
      commentaire: values.commentaire,
      adresse: values.adresse,
      typeRDV: { ...types.find((t) => t.code === values.typeRDV.code) as TypeRdv },
      journeeComplete: values.journeeComplete,
    }
    handleClose(action, newEvent)
  }

  const formikForm: EventForm = useForm<EventRequest>(
    {
      initialValues: {
        typeRDV: event?.typeRDV || { code: '', color: '', id: '', libelle: '', type: TypeRdvType.ActionGenerale },
        day: event?.dateDebut || dates?.start?.toISOString() || new Date().toISOString(),
        dateDebut: event?.dateDebut || dates?.start?.toISOString() || new Date().toISOString(),
        dateFin: event?.dateFin || dates?.end?.toISOString() || new Date(new Date().setHours(new Date().getHours() + 1)).toISOString(),
        journeeComplete: dates?.allDay || false,
        adresse: event?.adresse || { adresse1: '', adresse2: '', adresse3: '', codePostal: '', ville: '', digicode: '' },
        commentaire: event?.commentaire || '',
        id: event?.id || '',
        ...event,
      },
      onSubmit: onSubmit,
      validationSchema: eventSchema,
    },
  )

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      maxWidth="md"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitle>
          {
            action === 'add' ?
              'Nouveau rendez-vous' :
              `Modifier ${event?.typeRDV?.libelle}`
          }
          <CloseButton handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <FormBoldTitle required>
            Type de Rendez-vous
          </FormBoldTitle>
          <Field
            component={Select}
            name="typeRDV.code"
            displayEmpty
            renderValue={verifySelectFieldValue(formikForm.values.typeRDV.code)}
            value={formikForm.values.typeRDV.code || ''}
          >
            {
              types.map((rdvType, index) => (
                <MenuItem
                  value={rdvType.code}
                  key={`${rdvType.code}-${index}`}
                >
                  {rdvType.libelle}
                </MenuItem>
              ))
            }
          </Field>
          <GridContainer>
            <div>
              <FormBoldTitle required>
                Date de début
              </FormBoldTitle>
              <FormikDatePicker name="day" />
            </div>
            <div>
              <FormBoldTitle required>
                Heure de début
              </FormBoldTitle>
              <FormikDatePicker
                name="dateDebut"
                time
                disabled={formikForm.values.journeeComplete}
              />
            </div>
            <div>
              <FormBoldTitle required>
                Heure de fin
              </FormBoldTitle>
              <FormikDatePicker
                name="dateFin"
                time
                disabled={formikForm.values.journeeComplete}
              />
            </div>
            <div>
              <FormBoldTitle>
                &zwnj;
              </FormBoldTitle>
              <EntireDayContainer>
                <Field
                  name="journeeComplete"
                  component={CenteredCheckbox}
                  Label={{ label: 'Journée entière' }}
                  type="checkbox"
                />
              </EntireDayContainer>
            </div>
          </GridContainer>
          <FormBoldTitle>
            Commentaire général
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Votre message"
            rows={3}
            multiline
            name="commentaire"
          />
        </DialogContent>
        <DialogActionContainer>
          <FormButton onClick={() => handleClose()}>
            Annuler
          </FormButton>
          <FormButton
            type="submit"
            variant="contained"
          >
            {
              action === 'add' ?
                'Ajouter' :
                'Modifier'
            }
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default PlanningEventHandlerModal
