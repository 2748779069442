/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  // useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetMailRecipientsQuery,
  useGetMailSendersQuery,
  useGetMailTypeListQuery,
  useGetSinappsDocumentTypeListQuery,
} from 'store/api'
import {
  // useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  getAttachments,
  // setCourriers,
} from 'store/slices/courrierSlice'
import { useIsOnline } from 'helpers/hooks/useIsOnline'
// import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  MenuItem,
  Select,
} from '@mui/material'
// import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import CheckableCollapseCard from 'components/CheckableCollapseCard/CheckableCollapseCard'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  PieceJointe,
  TypeDocumentSinapps,
} from 'API/__generated__/Api'
import MailForm from 'pages/QuickActionPages/QaMailPage/MailForm'

/* Type declarations -------------------------------------------------------- */
interface CourrierRequest {
  sendTypes: {
    mail: boolean;
    editique: boolean;
    sinapps: boolean;
  };
  courriers: PieceJointe[];
}

const courrierSchema = Yup.object().shape<Shape<CourrierRequest>>({
  courriers: Yup.array(Yup.mixed<PieceJointe>()).required(),
}).required()

type NewCourrierForm = FormikContextType<CourrierRequest>

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;
`

interface ErrorField {
  error?: boolean;
}

const TwoGridContainer = styled(GridContainer)<ErrorField>`
  grid-template-columns: repeat(2, 1fr);
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;
  margin-top: -30px;

  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
  }
`

const Row = styled.div`
  display: flex;
  gap: 10px;

  div {
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CommunicationPageProps {}

const CommunicationPage: React.FC<CommunicationPageProps> = () => {
  const isOnline = useIsOnline()
  // const dispatch = useAppDispatch()
  // const navigate = useNavigate()
  const attachments: PieceJointe[] = useAppSelector(getAttachments)
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ triggerSubmit, setTriggerSubmit ] = useState<number>(0)

  const {
    currentData: documentTypeList = [],
    isFetching: isFetchingDocumentTypeList,
  } = useGetSinappsDocumentTypeListQuery()
  const {
    currentData: senders = [],
    isFetching: isFetchingSenders,
  } = useGetMailSendersQuery(caseId)
  const {
    currentData: recipients = [],
    isFetching: isFetchingRecipients,
  } = useGetMailRecipientsQuery(
    {
      dossier: caseId,
      interne: true,
      externe: true,
    },
  )
  const {
    currentData: mailTypes = [],
    isFetching: isFetchingMailTypeList,
  } = useGetMailTypeListQuery(caseId)

  const onSubmit = (values: CourrierRequest, { resetForm }: FormikHelpers<CourrierRequest>): void => {
    if (values.sendTypes.mail) {
      setTriggerSubmit(triggerSubmit + 1)
    }

    // const onFinish = (response: ApiResponse<void>): void => {
    //   if (!isApiError(response)) {
    //     toast.success(`Les courriers "${values.objet}" à bien été envoyé.`)
    //   } else {
    //     toast.error(`Une erreur est survenue lors de l'envoi du mail "${values.objet}".`)
    //   }
    // }

    // submitNewMail({
    //   caseId,
    //   data: { ...values, idsDocuments: controlledDocuments ? documents.map((doc) => doc.id || '') : values.idsDocuments },
    // }).then(onFinish).catch(console.error)
    // toast.success(`Envoi mail "${values.objet}" en cours...`)

    resetForm()
  }

  const formikForm: NewCourrierForm = useForm<CourrierRequest>(
    {
      initialValues: {
        sendTypes: {
          mail: false,
          editique: false,
          sinapps: false,
        },
        courriers: [],
      },
      validationSchema: courrierSchema,
      onSubmit,
    },
  )

  useEffect(() => {
    formikForm.setFieldValue('courriers', attachments)
  }, [ attachments ])

  const handleValue = (type: string, value?: PieceJointe[]): void => {
    formikForm.setFieldValue(type, value)
  }

  // const onEditClick = (id: string) => {
  //   // dispatch(setCourriers([ { ...data, docId: id } ]))
  //   navigate(`/dossiers/${caseId}/traveller/actions/document`)
  // }

  const onDocumentCheckClick = (doc: PieceJointe) => {
    const found = formikForm.values.courriers.some((v) => v.id === doc.id)

    if (!found) {
      handleValue('courriers', [ ...formikForm.values.courriers, doc ])
    } else {
      handleValue('courriers', formikForm.values.courriers.filter((v) => v.id !== doc.id))
    }
  }

  const modifyFileType = (doc: PieceJointe, fileType: string): void => {
    handleValue('courriers', [ ...formikForm.values.courriers.filter((v) => v.id !== doc.id), { ...doc, categorieSinapps: { code: fileType as TypeDocumentSinapps }} ])
  }

  const isLoading = useMemo(() => isFetchingDocumentTypeList || isFetchingRecipients || isFetchingMailTypeList || formikForm.isSubmitting,
    [
      isFetchingDocumentTypeList,
      isFetchingRecipients,
      isFetchingMailTypeList,
      formikForm.isSubmitting,
    ])

  return (
    <>
      <HeaderAction
        title="Communication"
        onSubmit={formikForm.handleSubmit}
      >
        <SubmitFormButton
          variant="contained"
          disabled={isLoading || !isOnline}
          onClick={() => formikForm.handleSubmit()}
        >
          Envoyer
        </SubmitFormButton>
      </HeaderAction>
      <PageContainer>
        {isLoading && <Loader />}
        <Form form={formikForm}>
          <FormBoldTitle>
            Type d'envois
          </FormBoldTitle>
          <Row>
            <CheckableButton
              checked={formikForm.values.sendTypes.mail}
              label="Mail"
              onChange={(e, c) => formikForm.setFieldValue('sendTypes.mail', c)}
            />
            <CheckableButton
              checked={formikForm.values.sendTypes.editique}
              label="Éditique"
              onChange={(e, c) => formikForm.setFieldValue('sendTypes.editique', c)}
            />
            <CheckableButton
              checked={formikForm.values.sendTypes.sinapps}
              label="Sinapps"
              onChange={(e, c) => formikForm.setFieldValue('sendTypes.sinapps', c)}
            />
          </Row>
          <FormBoldTitle>
            Documents
          </FormBoldTitle>
          {
            attachments.map((value, index) => (
              <CheckableCollapseCard
                key={`${value.id}-${index}`}
                label={value.libelle}
                checked={formikForm.values.courriers.some((v) => v.id === value.id)}
                onChange={() => onDocumentCheckClick(value)}
              >
                <TwoGridContainer>
                  <div>
                    {
                      formikForm.values.sendTypes.sinapps &&
                        <>
                          <FormBoldTitle>
                            Catégorie Sinapps
                          </FormBoldTitle>
                          <Select
                            value={formikForm.values.courriers?.find((doc) => doc.id === value.id)?.categorieSinapps?.code || ''}
                            onChange={(e) => modifyFileType(value, e.target.value)}
                            fullWidth
                            displayEmpty
                            size="small"
                            disabled={isFetchingDocumentTypeList}
                          >
                            {
                              documentTypeList.map(({ code, libelle }) => (
                                <MenuItem
                                  value={code}
                                  key={code}
                                >
                                  {libelle}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </>
                    }
                  </div>
                  <div>
                    {
                      formikForm.values.sendTypes.editique &&
                        <FormBoldTitle>
                          Type d'édition
                        </FormBoldTitle>
                    }
                  </div>
                </TwoGridContainer>
              </CheckableCollapseCard>
            ))
          }
        </Form>
        {
          formikForm.values.sendTypes.mail &&
            <MailForm
              senders={senders}
              isFetchingSenders={isFetchingSenders}
              recipients={recipients}
              mailTypes={mailTypes}
              documents={formikForm.values.courriers}
              controlledDocuments
              triggerOnSubmit={triggerSubmit}
            />
        }
      </PageContainer>
    </>
  )
}

export default CommunicationPage
