/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useIsOnline } from 'helpers/hooks/useIsOnline'

/* Component imports -------------------------------------------------------- */
import CaseSearcherButton from 'components/CaseSearcher/CaseSearcherButton'
import HeaderContainer from './HeadersComponents/HeaderContainer'
import HeaderCustomButton from './HeadersComponents/HeaderCustomButton'
import HeaderGroupContainer from './HeadersComponents/HeaderGroupContainer'
import HeaderTitle from './HeadersComponents/HeaderTitle'

/* Component declaration ---------------------------------------------------- */
interface HeaderWithSearchAndButtonProps {
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  disableButton?: boolean;
}

const HeaderWithSearchAndButton: React.FC<HeaderWithSearchAndButtonProps> = ({
  title,
  onClick,
  text,
  disableButton,
}) => {
  const isOnline = useIsOnline()

  return (
    <HeaderContainer id="header-container">
      <HeaderTitle title={title} />
      <HeaderGroupContainer>
        <CaseSearcherButton disabled={!isOnline} />
        <HeaderCustomButton
          text={text}
          onClick={onClick}
          disabled={disableButton}
        />
      </HeaderGroupContainer>
    </HeaderContainer>
  )
}

export default HeaderWithSearchAndButton
