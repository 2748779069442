/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useGetCaseDocumentsQuery,
  useGetSinappsDocumentTypeListQuery,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Checkbox,
  MenuItem,
  Select,
} from '@mui/material'
import { Field } from 'formik'
import {
  TextField,
  CheckboxWithLabel,
} from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Loader from 'components/Loader/Loader'

/* Type imports ------------------------------------------------------------- */
import type {
  DocumentAvecTypeRequest,
  TypeDocumentSinapps,
  TypeDocumentSinappsEnumLabel,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TableCard = styled(Card)`
  font-size: 14px;
  padding: 0px 20px;
  margin: -10px 0px;
`

const TableBoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`

interface TableRowProps {
  border?: boolean;
}

const TableRow = styled.div<TableRowProps>`
  height: 100%;
  display: grid;
  gap: 10px;
  padding: 5px 0px;
  justify-content: stretch;
  align-items: center;
  border-bottom: ${(props) => props.border ? `1px solid ${props.theme.colors.grey}` : undefined};
  grid-template-columns: 50px repeat(2, 1fr);
  width: 100%;
`

const FileTableRow = styled(TableRow)`
  grid-template-columns: 40px repeat(5, 1fr);
`

const TableBoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BlueLabelContainer = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
`

/* Component declaration ---------------------------------------------------- */
interface QaSinappsDealDissatisfactionProps {
  documents: DocumentAvecTypeRequest[];
  handleFileChange: (value: DocumentAvecTypeRequest[]) => void;
  closeDissatisfaction: boolean;
  setCloseDissatisfaction: (checked: boolean) => void;
  closeDissatisfactionComponent: React.ReactNode;
  caseId: string;
}

const QaSinappsDealDissatisfaction: React.FC<QaSinappsDealDissatisfactionProps> = ({
  closeDissatisfaction,
  setCloseDissatisfaction,
  closeDissatisfactionComponent,
  caseId,
  documents = [],
  handleFileChange,
}) => {
  const {
    currentData: documentList = [],
    isFetching: isFetchingDocumentList,
  } = useGetCaseDocumentsQuery({ dossier: caseId })
  const {
    currentData: documentTypeList = [],
    isFetching: isFetchingDocumentTypeList,
  } = useGetSinappsDocumentTypeListQuery()

  const handleFileCheckAll = (): void => {
    if (documents?.length === documentList.length && documents.length > 0) {
      handleFileChange([])
    } else {
      handleFileChange(documentList.map((doc) => ({ idFichier: doc.id || doc.libelle, type: doc.categorieSinapps?.code as TypeDocumentSinapps })))
    }
  }

  const handleFileChecked = (fileId: string, fileType?: TypeDocumentSinappsEnumLabel): void => {
    if (documents.some((doc) => doc.idFichier === fileId)) {
      handleFileChange([ ...documents.filter((doc) => doc.idFichier !== fileId) ])
    } else {
      handleFileChange([ ...documents, { idFichier: fileId, type: fileType?.code } ])
    }
  }

  const modifyFileType = (fileId: string, fileType: string): void => {
    handleFileChange([ ...documents.filter((doc) => doc.idFichier !== fileId), { idFichier: fileId, type: fileType as TypeDocumentSinapps } ])
  }

  if (isFetchingDocumentList || isFetchingDocumentTypeList)
    return <Loader />

  return (
    <>
      <TableCard>
        <TableBoldUppercaseTitle>
          <FileTableRow>
            <Checkbox
              onChange={handleFileCheckAll}
              checked={documents.length === documentList.length && documentList.length > 0}
            />
            <div>
              Libellé
            </div>
            <div>
              Date de création
            </div>
            <div>
              Nom Document
            </div>
            <div>
              Type
            </div>
            <div>
              Origine
            </div>
          </FileTableRow>
        </TableBoldUppercaseTitle>
        <TableBoldSeparator />
        {
          documentList.map((document, index) => (
            <FileTableRow
              border={index !== (documentList.length || 0) -1}
              key={`${document.id}-${index}`}
            >
              <Checkbox
                checked={documents?.some((doc) => doc.idFichier === document.id)}
                onChange={() => handleFileChecked(document.id || document.libelle, document.categorieSinapps)}
              />
              <div>
                {document.libelle}
              </div>
              <div>
                {DateUtils.APIStrToLocalDateString(document.dateCreation)}
              </div>
              <div>
                {document.fileName}
              </div>
              {
                isValidString(document.categorieSinapps?.code) &&
                  <Select
                    value={documents?.find((doc) => doc.idFichier === document.id)?.type || document.categorieSinapps?.code}
                    onChange={(e): void => modifyFileType(document.id || document.libelle, e.target.value)}
                    fullWidth
                    displayEmpty
                    size="small"
                    disabled={isFetchingDocumentTypeList}
                  >
                    {
                      documentTypeList.map(({ code, libelle }) => (
                        <MenuItem
                          value={code}
                          key={code}
                        >
                          {libelle}
                        </MenuItem>
                      ))
                    }
                  </Select>
              }
              <div>
                {document.origine}
              </div>
            </FileTableRow>
          ))
        }
      </TableCard>
      <FormBoldTitle required>
        Commentaire
      </FormBoldTitle>
      <Field
        component={TextField}
        name="dealDissatisfaction.commentaire"
        placeholder="Votre message"
        rows={3}
        multiline
      />
      <BlueLabelContainer>
        <Field
          id="close-dissatisfaction"
          component={CheckboxWithLabel}
          Label={{ label: "Clôturer l'insatifaction" }}
          type="checkbox"
          checked={closeDissatisfaction}
          onChange={(e: React.SyntheticEvent, c: boolean): void => setCloseDissatisfaction(c)}
        />
      </BlueLabelContainer>
      {closeDissatisfaction && closeDissatisfactionComponent}
    </>
  )
}

export default QaSinappsDealDissatisfaction
