/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useOutletContext } from 'react-router-dom'
import { useGetConfidentialAnomalyListQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import CheckableButton from 'components/CheckableButton/CheckableButton'

/* Type imports ------------------------------------------------------------- */
import type { Anomalie } from 'API/__generated__/Api'
import type { TravelerForm } from 'types/TravelerForm'

/* Styled components -------------------------------------------------------- */
interface ErrorField {
  error?: boolean;
}

const TwoGridContainer = styled.div<ErrorField>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
  }
  gap: 10px;

  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;

  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;
`

const FormContainer = styled.div`
  padding-bottom: 40px;
`

const BiggerCheckableButton = styled(CheckableButton)`
  height: 65px;
`

/* Component declaration ---------------------------------------------------- */
interface ConfidentialPageProps {}

const ConfidentialPage: React.FC<ConfidentialPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()

  const {
    currentData: anomalyList = [],
    isFetching: isFetchingAnomalyList,
  } = useGetConfidentialAnomalyListQuery()

  const handleValue = (type: string, value?: string | Anomalie[]): void => {
    formikForm.setFieldValue(`confidentiel.${type}`, value)
  }

  const handleCheckedButton = (value: string, checked: boolean): void => {
    const newValues: Anomalie[] | undefined = [ ...formikForm.values.confidentiel.anomalies ].filter((ano) => ano.code !== value)

    newValues?.push({ code: value, libelle: '', ...formikForm.values.confidentiel.anomalies.find((ano) => ano.code === value), reponse: checked })
    handleValue('anomalies', newValues)
  }

  if (isFetchingAnomalyList) {
    return <Loader />
  }

  if (!formikForm.values.confidentiel) {
    return null
  }

  return (
    <FormContainer>
      <TravelerLargeTitle>
        Confidentiel
      </TravelerLargeTitle>
      <FormBoldTitle bigger>
        Anomalies
      </FormBoldTitle>
      <TwoGridContainer>
        {
          anomalyList.map((anomaly, index) => (
            <BiggerCheckableButton
              key={`${anomaly.question.code}-${index}`}
              checked={formikForm.values.confidentiel.anomalies?.some((ano) => (ano.code === anomaly.question.code) && ano.reponse) || false}
              onChange={(e, c): void => handleCheckedButton(anomaly.question.code, c)}
              label={anomaly.question.libelle}
              disabled={formikForm.values.disabled}
            />
          ))
        }
      </TwoGridContainer>
      <FormBoldTitle>
        Commentaire
      </FormBoldTitle>
      <Field
        component={TextField}
        placeholder="Votre message"
        rows={5}
        multiline
        name="confidentiel.commentaire"
        disabled={formikForm.values.disabled}
      />
      <FormBoldTitle bigger>
        Note confidentielle
      </FormBoldTitle>
      <FormBoldTitle>
        Commentaire
      </FormBoldTitle>
      <Field
        component={TextField}
        placeholder="Votre message"
        rows={5}
        multiline
        name="confidentiel.noteConfidentielle"
        disabled={formikForm.values.disabled}
      />
    </FormContainer>
  )
}

export default ConfidentialPage
