/* Framework imports -------------------------------------------------------- */
import type React from 'react'
import { useEffect } from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  useGetApplicationGarantieListQuery,
  useGetAssureTypeListQuery,
  useGetCaseDocumentsQuery,
  useGetCaseEventDocumentsQuery,
  useGetCaseInfosQuery,
  useGetCaseReportsQuery,
  useGetCaseStateCodeListQuery,
  useGetCaseWorkflowQuery,
  useGetCauseNotDeletedReasonListQuery,
  useGetCommentActionQuery,
  useGetCompensationDamagePositionListQuery,
  useGetConfidentialAnomalyListQuery,
  useGetConventionListQuery,
  useGetDisasterNatureListQuery,
  useGetDisasterRexTypeListQuery,
  useGetDisasterSinappsNatureCauseDetailListQuery,
  useGetDocumentCategoryListQuery,
  useGetEventDocumentsFamilleActionListQuery,
  useGetEventDocumentsRecipientListQuery,
  useGetFamilleAnnuaireListQuery,
  useGetHistoriqueQuery,
  useGetInternalExchangesRecipientListQuery,
  useGetIrsiBaremeListQuery,
  useGetIrsiTrancheListQuery,
  useGetJustificationListQuery,
  useGetLettreAcceptationListQuery,
  useGetMailRecipientsQuery,
  useGetMailSendersQuery,
  useGetNatureBienListQuery,
  useGetNatureDetailBienListQuery,
  useGetDetailBienRexListQuery,
  useGetPaymentTypeListQuery,
  useGetPersonQualifiantListQuery,
  useGetPersonRoleListQuery,
  useGetPlanningQuery,
  useGetPlanningTypesQuery,
  useGetPolitenessListQuery,
  useGetQualiteListQuery,
  useGetReparabiliteListQuery,
  useGetResponsabilityListQuery,
  useGetRiskNotVerifiedReasonListQuery,
  useGetRiskTypeListQuery,
  useGetRiskUsageListQuery,
  useGetRoomTypeListQuery,
  useGetSinappsBienTypeListQuery,
  useGetSinappsCompensationModeListQuery,
  useGetSinappsGarantieListQuery,
  useGetSinappsReportTodoListQuery,
  useGetTVAAssujettissementListQuery,
  useGetTVARateListQuery,
  useGetUniteMesureListQuery,
  useGetYesNoIndeterminedQuery,
  useLazyGetCaseTravelerQuery,
  useGetReportTypeListQuery,
  useGetCloseReportTypeListQuery,
  useGetTasksQuery,
  useGetInternalExchangesQuery,
  useGetNotificationsQuery,
} from 'store/api'
import {
  useAppDispatch,
  useAppSelector,
  useAuthInfo,
} from 'store/hooks'
import { getNetworkState } from 'store/slices/networkSlice'
import {
  setCaseIsReadyForOffline,
  setCaseReportEdition,
  setCaseReportErrorForm,
  setCaseReportFormikForm,
} from 'store/slices/travelerSlice'
import {
  downloadOfflineDocument,
  downloadOfflineHistoryDocument,
} from 'helpers/offlineMediaHelper'
import DateUtils from 'helpers/DateUtils'

/* Type imports ------------------------------------------------------------- */
import type { NetworkState } from 'store/slices/networkSlice'
import { TypeEvenementiel } from 'API/__generated__/Api'
import { useForm } from 'components/FormikLogic/FormikLogic'
import type {
  TravelerAndUtils,
  TravelerForm,
} from 'types/TravelerForm'
import { travelerSchema } from 'types/TravelerForm'
import TravelerFormInitialValues from 'types/TravelerFormInitialValues'

/* Component declaration ---------------------------------------------------- */
interface DownloadOfflineActiveProps {
  caseId: string;
}

const DownloadOfflineActive: React.FC<DownloadOfflineActiveProps> = ({ caseId }) => {
  const dispatch = useAppDispatch()
  const authInfo = useAuthInfo()

  const { fetchingRouteList = []}: NetworkState = useAppSelector(getNetworkState)

  const formikForm: TravelerForm = useForm<TravelerAndUtils>(
    {
      initialValues: TravelerFormInitialValues as TravelerAndUtils,
      validationSchema: travelerSchema,
    },
  )

  const useNeutralDataService = () => {
    useGetPlanningTypesQuery()
    useGetCommentActionQuery()
    useGetRiskUsageListQuery()
    useGetRiskTypeListQuery()
    useGetRiskNotVerifiedReasonListQuery()
    useGetDetailBienRexListQuery()
    useGetDisasterSinappsNatureCauseDetailListQuery()
    useGetDisasterRexTypeListQuery()
    useGetCauseNotDeletedReasonListQuery()
    useGetRoomTypeListQuery()
    useGetNatureBienListQuery()
    useGetNatureBienListQuery()
    useGetReparabiliteListQuery()
    useGetJustificationListQuery()
    useGetCompensationDamagePositionListQuery()
    useGetLettreAcceptationListQuery()
    useGetConfidentialAnomalyListQuery()
    useGetPaymentTypeListQuery()
    useGetSinappsReportTodoListQuery()
    useGetDocumentCategoryListQuery({ onlySinapps: false })
    useGetApplicationGarantieListQuery()
    useGetAssureTypeListQuery()
    useGetDisasterNatureListQuery()
    useGetCaseStateCodeListQuery()
    useGetPersonQualifiantListQuery()
    useGetFamilleAnnuaireListQuery()
    useGetTVAAssujettissementListQuery()
    useGetResponsabilityListQuery()
    useGetYesNoIndeterminedQuery()
    useGetUniteMesureListQuery()
    useGetTVARateListQuery()
    useGetPolitenessListQuery()
    useGetCauseNotDeletedReasonListQuery()
    useGetSinappsBienTypeListQuery()
    useGetSinappsCompensationModeListQuery()
    useGetIrsiTrancheListQuery()
    useGetIrsiBaremeListQuery()
    useGetCloseReportTypeListQuery()
  }

  useNeutralDataService()
  const [ getCaseTravelerQuery, { isFetching: isFetchingCaseTraveler } ] = useLazyGetCaseTravelerQuery()
  useGetCaseWorkflowQuery(caseId)
  useGetCaseInfosQuery(caseId)
  useGetTasksQuery({ dossier: caseId, collaborateur: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '' })
  useGetInternalExchangesQuery({ dossier: caseId })
  useGetNotificationsQuery({ dossier: caseId })
  useGetPlanningQuery({
    expert: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
    dateDebut: DateUtils.planningStartDateApi,
    dateFin: DateUtils.planningEndDateApi,
  })
  useGetMailSendersQuery(caseId)
  useGetMailRecipientsQuery({ dossier: caseId, externe: true, interne: true })
  useGetInternalExchangesRecipientListQuery({ dossier: caseId, assistante: true, expert: true })
  useGetNatureDetailBienListQuery(caseId)
  useGetSinappsGarantieListQuery(caseId)
  useGetConventionListQuery(caseId)
  useGetEventDocumentsFamilleActionListQuery({ dossier: caseId, filtreEvenementiel: TypeEvenementiel.Dossier })
  useGetEventDocumentsFamilleActionListQuery({ dossier: caseId, filtreEvenementiel: TypeEvenementiel.Rapport })
  useGetEventDocumentsRecipientListQuery(caseId)
  useGetQualiteListQuery(caseId)
  useGetPersonRoleListQuery(caseId)
  useGetReportTypeListQuery(caseId)

  // Documents
  const { currentData: documents = [], isFetching: isFetchingDocuments } = useGetCaseDocumentsQuery({ dossier: caseId })
  const { currentData: eventDocuments = [], isFetching: isFetchingEventDocuments } = useGetCaseEventDocumentsQuery({ dossier: caseId, avecBrouillons: true })
  const { currentData: history = [], isFetching: isFetchingHistory } = useGetHistoriqueQuery(caseId)

  useEffect(() => {
    if (!isFetchingDocuments) {
      const promises: Promise<void>[] = []

      documents.forEach((document) => {
        promises.push(downloadOfflineDocument(document, caseId))
      })
      try {
        Promise.all(promises).catch(console.error)
      } catch(error) {
        console.error(error)
      }
    }
  }, [ isFetchingDocuments ])

  useEffect(() => {
    if (!isFetchingEventDocuments) {
      const promises: Promise<void>[] = []

      eventDocuments.forEach((document) => {
        promises.push(downloadOfflineDocument(document, caseId))
      })
      try {
        Promise.all(promises).catch(console.error)
      } catch(error) {
        console.error(error)
      }
    }
  }, [ isFetchingEventDocuments ])

  useEffect(() => {
    if (!isFetchingHistory) {
      const promises: Promise<void>[] = []

      history.flatMap((h) => h.pieceJointes).forEach((document) => {
        promises.push(downloadOfflineHistoryDocument(document, caseId))
      })
      try {
        Promise.all(promises).catch(console.error)
      } catch(error) {
        console.error(error)
      }
    }
  }, [ isFetchingHistory ])

  // Reports
  const { currentData: reports = [], isFetching: isFetchingCaseReport } = useGetCaseReportsQuery(caseId)
  useEffect(() => {
    if (!isFetchingCaseReport) {
      reports.forEach((report) => {
        getCaseTravelerQuery(report.id)
          .then((data) => {
            dispatch(setCaseReportFormikForm({ caseId, reportId: report.id, formikForm: { ...formikForm, values: { ...formikForm.values, ...data.data }}}))
            dispatch(setCaseReportErrorForm({ caseId, reportId: report.id, errorForm: { ...formikForm, values: { ...formikForm.values, ...data.data }}}))
            dispatch(setCaseReportEdition({ caseId, reportId: report.id, edition: data.data?.derniereSauvegardeA || new Date().toISOString() }))
          })
          .catch(console.error)
      })
    }
  }, [ isFetchingCaseReport ])

  useEffect(() => {
    if (!isFetchingCaseReport && !isFetchingCaseTraveler && fetchingRouteList.length === 0 && !isFetchingDocuments) {
      dispatch(setCaseIsReadyForOffline({ caseId, isReadForOffline: true }))
    }
  }, [ isFetchingCaseReport, isFetchingCaseTraveler, fetchingRouteList, isFetchingDocuments ])

  return null
}

export default DownloadOfflineActive
