/* Module imports ----------------------------------------------------------- */
import { roundNumberFour } from './helpers/calculs'

/* Type imports ------------------------------------------------------------- */
import type {
  Dommage,
  Indemnisation,
} from './types/DommageIndemnisation'

/* Function declarations ---------------------------------------------------- */
export const calculIndemnisation = (dommages: Dommage[], existingIndemnisations: Indemnisation[]): Indemnisation[] => {
  const indemnisations: Indemnisation[] = []

  dommages.forEach((dmg) => {
    let indemEnCours: Indemnisation | undefined = undefined
    const montantDecouvert: number = roundNumberFour(dmg.vetuste.montantVetuste > dmg.vetuste.montantVetusteRecuperable ? dmg.vetuste.montantVetuste - dmg.vetuste.montantVetusteRecuperable : 0)

    if (indemnisations.length > 0) {
      indemEnCours = indemnisations.find((indemn) =>
        indemn.natureDuBien?.code === dmg.natureDuBien?.code &&
        indemn.detailDuBien?.code === dmg.detailDuBien?.code &&
        indemn.typeDeReparation.code === dmg.typeDeReparation.code,
      )
    }
    const existing = existingIndemnisations.find((indemn) =>
      (indemn.natureDuBien?.code === dmg.natureDuBien?.code || indemn.natureDuBien?.code === dmg.sinapps?.natureDuBienSinapps?.code) &&
      (indemn.detailDuBien?.code === dmg.detailDuBien?.code || indemn.detailDuBien?.code === dmg.sinapps?.detailDuBienSinapps?.code) &&
      indemn.typeDeReparation.code === dmg.typeDeReparation.code,
    )
    if (!indemEnCours || indemnisations.length === 0) {
      indemnisations.push({
        ...dmg,
        ...dmg.valeur,
        ...dmg.vetuste,
        montantVetusteDeduiteTTC: dmg.vetuste.montantVetusteDeduite,
        montantDecouvert,
        garantie: existing?.garantie,
      })
    } else {
      const indexIdem = indemnisations.findIndex((indemn) =>
        indemn.natureDuBien?.code === dmg.natureDuBien?.code &&
        indemn.detailDuBien?.code === dmg.detailDuBien?.code &&
        indemn.typeDeReparation.code === dmg.typeDeReparation.code,
      )

      indemnisations[indexIdem].montantHT += dmg.valeur.montantHT
      indemnisations[indexIdem].montantTTC += dmg.valeur.montantTTC
      indemnisations[indexIdem].montantVetusteDeduiteTTC += dmg.vetuste.montantVetusteDeduite
      indemnisations[indexIdem].montantVetusteRecuperable += dmg.vetuste.montantVetusteRecuperable
      indemnisations[indexIdem].montantDecouvert += montantDecouvert
    }
  })

  return indemnisations
}
