/* Framework imports -------------------------------------------------------- */
import React from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useDeleteDocumentMutation,
  useUpdateDocumentNameMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { toast } from 'react-toastify'
import DocumentNameEditor from './DocumentNameEditor'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  PieceJointe,
  UpdateDocumentRequest,
} from 'API/__generated__/Api'
import { TypePJ } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const attachmentSchema = Yup.object().shape<Shape<UpdateDocumentRequest>>({
  nom: Yup.string().required('Le nom est obligatoire'),
  categorie: Yup.string(),
})

type AttachmentForm = FormikContextType<UpdateDocumentRequest>

/* Component declaration ---------------------------------------------------- */
interface DocumentNameEditorFormProps {
  document: PieceJointe;
  onEdition: () => void;
}

const DocumentNameEditorForm: React.FC<DocumentNameEditorFormProps> = ({
  document,
  onEdition,
}) => {
  const [
    deleteDocument,
    { isLoading: isDeletingDocument },
  ] = useDeleteDocumentMutation()

  const [
    updateDocument,
    { isLoading: isUpdatingDocument },
  ] = useUpdateDocumentNameMutation()

  const onDeletion = async () => {
    if (document.id) {
      await deleteDocument({ id: document.id, typePJ: TypePJ.Doc })
    }
  }

  const onSubmit = async (values: UpdateDocumentRequest, { setSubmitting }: FormikHelpers<UpdateDocumentRequest>) => {
    if (document.id) {
      const response = await updateDocument({ id: document.id, data: values })

      if (isApiError(response)) {
        toast.error('Une erreur est survenue.')
      } else {
        toast.success('Le document à bien été mis à jour.')
      }
    }
    setSubmitting(false)
  }

  const formikForm: AttachmentForm = useForm<UpdateDocumentRequest>(
    {
      initialValues: {
        nom: document.libelle,
        categorie: document.categorie?.code,
      },
      onSubmit: onSubmit,
      validationSchema: attachmentSchema,
    },
  )

  return (
    <Form form={formikForm}>
      <DocumentNameEditor
        document={document}
        fieldNames={{ name: 'nom', category: 'categorie' }}
        category={formikForm.values.categorie}
        onEdition={onEdition}
        onRemove={onDeletion}
        disabled={isDeletingDocument || isUpdatingDocument}
      />
    </Form>
  )
}

export default DocumentNameEditorForm
