/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { DialogTitle as MuiDialogTitle } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
const DialogTitle = styled(MuiDialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 5px;
  padding-bottom: 0px;
  text-transform: uppercase;
  text-align: center;
`

export default DialogTitle
