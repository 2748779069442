/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useAppDispatch } from 'store/hooks'
import { openMediaLibrary } from 'store/slices/mediaLibrarySlice'
import { isEditableFileType } from 'helpers/getMediaFileType'
import { isValidString } from 'helpers/isValidString'
import { openFileInNewWindow } from 'helpers/offlineMediaHelper'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import ShowMore from 'components/ShowMore/ShowMore'
import VignetteRendererWithFilename from 'components/MediaRenderer/VignetteRendererWithFilename'
import VignetteRendererWithFilenameOffline from 'components/MediaRenderer/VignetteRendererWithFilenameOffline'
import CaseSidebarArrowTitle from '../CaseSidebarComponents/CaseSidebarArrowTitle/CaseSidebarArrowTitle'

/* Type imports ------------------------------------------------------------- */
import type { OfflineCaseDocument } from 'store/db'
import type { PieceJointe } from 'API/__generated__/Api'

/* Internal variables ------------------------------------------------------- */
const DOCUMENT_DEFAULT_LIMIT: number = 8

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;

  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarMediaProps {
  isFetching: boolean;
  documents: PieceJointe[];
  importedDocumentList: OfflineCaseDocument[];
  setOpenedMenu: () => void;
  documentLimit?: number;
  onClose: () => void;
  isOnline: boolean;
}

const CaseSidebarMedia: React.FC<CaseSidebarMediaProps> = ({
  isFetching,
  documents,
  importedDocumentList,
  setOpenedMenu,
  documentLimit = DOCUMENT_DEFAULT_LIMIT,
  onClose,
  isOnline,
}) => {
  const dispatch = useAppDispatch()
  const [ showAll, setShowAll ] = useState<boolean>(false)

  const getDocuments = (): PieceJointe[] => {
    if (showAll) {
      return documents
    }
    return documents.slice(0, documentLimit)
  }

  const handleMediaClick = (document: PieceJointe): void => {
    if (isEditableFileType(document)) {
      dispatch(openMediaLibrary(document))
      onClose()
    } else if (isValidString(document.url) && isValidString(document.id)) {
      openFileInNewWindow(isOnline, document.id || '', document.url)
    }
  }

  return (
    <div>
      <CaseSidebarArrowTitle
        setOpenedMenu={setOpenedMenu}
        title="Médias"
      />
      {
        isFetching ?
          <CircularProgress /> :
          <>
            <GridContainer>
              {
                importedDocumentList.map((document) => (
                  <VignetteRendererWithFilenameOffline
                    key={document.fileId}
                    file={document.blob}
                    name={document.name}
                    onClick={() => openFileInNewWindow(false, document.fileId || '', '')}
                    width="min(100px, 100%)"
                    height="70px"
                  />
                ))
              }
              {
                getDocuments().map((document, index) => (
                  <VignetteRendererWithFilename
                    key={`${document.fileName}-${index}`}
                    document={document}
                    onClick={() => handleMediaClick(document)}
                    width="min(100px, 100%)"
                    height="70px"
                  />
                ))
              }
            </GridContainer>
            {
              documents.length - documentLimit > 0 &&
                <ShowMore
                  onClick={() => setShowAll(!showAll)}
                  isOpen={showAll}
                >
                  {
                    showAll === false ?
                      `Afficher les ${documents.length - documentLimit} autres documents` :
                      'Réduire'
                  }
                </ShowMore>
            }
          </>
      }
    </div>
  )
}

export default CaseSidebarMedia
