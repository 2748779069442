/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'

/* Module imports ----------------------------------------------------------- */
import { useAppDispatch } from 'store/hooks'
import { closeQuickActions } from 'store/slices/quickActionModalSlice'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import ImportAttachmentModal from 'components/ImportAttachmentModal/ImportAttachmentModal'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import CustomIconButtonContainer from 'components/IconButtons/CustomIconButton/CustomIconButtonContainer'

/* Type imports ------------------------------------------------------------- */
import type { SvgIconComponent } from '@mui/icons-material'

/* Styled components -------------------------------------------------------- */
const Span = CustomIconButtonContainer.withComponent('span')

/* Component declaration ---------------------------------------------------- */
interface TravelerAttachmentButtonProps {
  caseId: string;
  Icon: SvgIconComponent;
  disabled?: boolean;
}

const TravelerAttachmentButton: React.FC<TravelerAttachmentButtonProps> = ({ caseId, Icon, disabled }) => {
  const dispatch = useAppDispatch()

  const [ files, setFiles ] = useState<File[]>([])
  const [ open, setOpen ] = useState<boolean>(false)

  const handleCloseModal = (closeQuickActionModal: boolean = false): void => {
    setOpen(false)
    setFiles([])
    if (closeQuickActionModal) {
      dispatch(closeQuickActions())
    }
  }

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      setFiles([ ...Object.values(e.target.files || {}) ])
      setOpen(true)
    }
  }

  return (
    <>
      <AttachmentButton
        onChange={handleOnFileChange}
        disabled={disabled}
      >
        <Button
          variant="contained"
          component={Span}
          disabled={disabled}
        >
          <Icon />
        </Button>
      </AttachmentButton>
      {
        files.length > 0 && open &&
          <ImportAttachmentModal
            handleClose={handleCloseModal}
            files={files}
            setFiles={setFiles}
            caseId={caseId}
          />
      }
    </>
  )
}

export default TravelerAttachmentButton
