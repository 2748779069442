/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Styled components -------------------------------------------------------- */
const WelcomeMessage = styled.div`
  font-size: 1.8rem;
  color: ${(props) => props.theme.palette.secondary.main};
  margin-bottom: 2rem;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.main} {
    font-size: 1.2rem;
  }
`

const NomadContainer = styled.span`
  font-weight: 100;
  font-size: 3rem;
`

const NomadNumberContainer = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.palette.warning.main};
`

/* Component declaration ---------------------------------------------------- */
interface AuthWelcomeMessageProps {}

const AuthWelcomeMessage: React.FC<AuthWelcomeMessageProps> = () => {

  return (
    <WelcomeMessage>
      <b>
        Bienvenue dans
      </b>
      <br />
      <NomadContainer>
        Nomad
        <NomadNumberContainer>
          9
        </NomadNumberContainer>
      </NomadContainer>
    </WelcomeMessage>
  )
}

export default AuthWelcomeMessage
