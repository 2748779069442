/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import CaseWorkflowStep from './CaseWorkflowStep'

/* Type imports ------------------------------------------------------------- */
import type { JalonSimplifie } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
`

const StepContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  gap: 5px;

  align-items: stretch;
  justify-content: stretch;

  background-color: ${(props) => props.theme.palette.background.paper};
`

const BoldSeparator = styled.div`
  margin: 5px 10px 0px 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowProps {
  workflow: JalonSimplifie[];
  isFetching: boolean;
}

const CaseWorkflow: React.FC<CaseWorkflowProps> = ({ workflow, isFetching }) => {
  const numberOfCols = 4

  const jalonArrays: JalonSimplifie[][] = useMemo(() => {
    const workflowArr = [ ...workflow ]
    const newJalonArrays = []

    for (let i = 0; i < workflowArr.length; i += numberOfCols) {
      const chunk = workflowArr.slice(i, i + numberOfCols)
      newJalonArrays.push(chunk)
    }

    return newJalonArrays
  }, [ workflow ])

  if (isFetching) {
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    )
  }

  return (
    <>
      {
        jalonArrays.map((jalonArray, indexJalonArray) => (
          <div key={`jalonArray-${indexJalonArray}`}>
            {indexJalonArray !== 0 && <BoldSeparator />}
            <StepContainer>
              {
                jalonArray.map((jalon, jalonIndex) => (
                  <CaseWorkflowStep
                    key={`${jalon.libelle}-${jalonIndex}`}
                    complete={jalon.fait}
                    stepName={jalon.libelle}
                    sideBorder={jalonIndex !== numberOfCols - 1}
                  />
                ))
              }
            </StepContainer>
          </div>
        ))
      }
    </>
  )
}

export default CaseWorkflow
