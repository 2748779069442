/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useGetCaseInfosQuery } from 'store/api'
import { useIsOnline } from 'helpers/hooks/useIsOnline'

/* Component imports -------------------------------------------------------- */
import QuickActionsAttachmentButton from './QuickActionsAttachmentButton'
import QuickActionsButton from './QuickActionsButton'

/* Styled components -------------------------------------------------------- */
const QuickActionsButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
`

/* Component declaration ---------------------------------------------------- */
interface QuickActionsButtonsProps {
  caseId: string;
}

const QuickActionsButtons: React.FC<QuickActionsButtonsProps> = ({ caseId }) => {
  const isOnline = useIsOnline()

  const { currentData: caseInfos } = useGetCaseInfosQuery(caseId)

  return (
    <QuickActionsButtonsContainer>
      <QuickActionsButton
        actionType="tache"
        caseId={caseId}
      >
        Ajouter une tâche
      </QuickActionsButton>
      <QuickActionsButton
        caseId={caseId}
        actionType="commentaire"
      >
        Ajouter un commentaire
      </QuickActionsButton>
      <QuickActionsAttachmentButton
        caseId={caseId}
        disabled={!isOnline}
      />
      <QuickActionsButton
        caseId={caseId}
        actionType="mail"
      >
        Envoyer un mail
      </QuickActionsButton>
      <QuickActionsButton
        caseId={caseId}
        actionType="echange-interne"
      >
        Envoyer un échange interne
      </QuickActionsButton>
      {
        caseInfos?.mission.origine?.code === 'SIN' &&
          <React.Fragment>
            <QuickActionsButton
              caseId={caseId}
              actionType="commande-sinapps"
              disabled={!isOnline}
            >
              Envoyer une commande SINAPPS
            </QuickActionsButton>
            <QuickActionsButton
              caseId={caseId}
              actionType="fiche-vigilance"
              disabled={!isOnline}
            >
              Fiches de vigilance
            </QuickActionsButton>
          </React.Fragment>
      }
      <QuickActionsButton
        caseId={caseId}
        actionType="courrier"
      >
        Générer / voir les courriers
      </QuickActionsButton>
    </QuickActionsButtonsContainer>
  )
}

export default QuickActionsButtons
