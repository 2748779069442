/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { TextField } from '@mui/material'
import {
  Field,
  useField,
} from 'formik'
import { Autocomplete } from 'formik-mui'

/* Type imports ------------------------------------------------------------- */
import type { AutocompleteRenderInputParams } from '@mui/material'
import type { FieldAttributes } from 'formik'
import type { CodeLabel } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface AutocompleteFieldProps<T> {
  name: string;
  options: T[];
  placeholder?: string;
  size?: string;
  getOptionLabel?: (option: T) => string;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  freeSolo?: boolean;
}

const AutocompleteField: React.FC<FieldAttributes<AutocompleteFieldProps<unknown>>> = ({
  name,
  placeholder = 'Sélectionnez',
  options,
  getOptionLabel,
  isOptionEqualToValue,
  freeSolo = false,
  ...props
}) => {
  const [ , meta ] = useField(name)

  const defaultIsOptionEqualToValue = (option: CodeLabel, value: CodeLabel): boolean => {
    return option.code === value.code || value.code === ''
  }

  const defaultGetOptionLabel = (option: CodeLabel | string): string => {
    if (typeof option === 'object') {
      if (option?.libelle) return option?.libelle || ''
      if (option.code) return option.code || ''
    }
    if (typeof option === 'string') return option
    return ''
  }

  return (
    <Field
      component={Autocomplete}
      name={name}
      options={options}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue || defaultIsOptionEqualToValue}
      freeSolo={freeSolo}
      renderInput={
        (params: AutocompleteRenderInputParams): React.ReactElement => (
          <TextField
            {...params}
            name={name}
            error={meta.touched && meta.error !== undefined}
            helperText={meta.touched && meta.error?.toString()}
            placeholder={placeholder}
            variant="outlined"
          />
        )
      }
      {...props}
    />
  )
}

export default AutocompleteField
