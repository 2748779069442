/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from 'store/db'
import {
  useGetCaseDocumentsQuery,
  useGetCaseInfosQuery,
  useGetCaseWorkflowQuery,
  useGetHistoriqueQuery,
  useGetInternalExchangesQuery,
  useGetNotificationsQuery,
  useGetTasksQuery,
} from 'store/api'
import { useAuthInfo } from 'store/hooks'
import { useIsOnline } from 'helpers/hooks/useIsOnline'

/* Component imports -------------------------------------------------------- */
import DrawerContent from 'components/Drawer/DrawerContent'
import CaseSidebarHeader from './CaseSidebarComponents/CaseSidebarHeader/CaseSidebarHeader'
import CaseWorkflow from './CaseWorkflow/CaseWorkflow'
import CaseSidebarPerson from './CaseSidebarPerson/CaseSidebarPerson'
import CaseSidebarTiers from './CaseSidebarTiers/CaseSidebarTiers'
import CaseSidebarPostIt from './CaseSidebarPostIt/CaseSidebarPostIt'
import CaseSidebarMedia from './CaseSidebarMedia/CaseSidebarMedia'
import CaseSidebarTask from './CaseSidebarTask/CaseSidebarTask'
import CaseSidebarInternalExchange from './CaseSidebarInternalExchange/CaseSidebarInternalExchange'
import CaseSidebarNotification from './CaseSidebarNotification/CaseSidebarNotification'
import CaseSidebarHistory from './CaseSidebarHistory/CaseSidebarHistory'
import CaseSidebarHeaderSubMenu from './CaseSidebarHeaderSubMenu/CaseSidebarHeaderSubMenu'
import CaseSidebarMediaMenu from './CaseSidebarMedia/CaseSidebarMediaMenu'
import CaseSidebarTaskMenu from './CaseSidebarTask/CaseSidebarTaskMenu'
import CaseSidebarInternalExchangeMenu from './CaseSidebarInternalExchange/CaseSidebarInternalExchangeMenu'
import CaseSidebarNotificationMenu from './CaseSidebarNotification/CaseSidebarNotificationMenu'
import CaseSidebarHistoryMenu from './CaseSidebarHistory/CaseSidebarHistoryMenu'

/* Type imports ------------------------------------------------------------- */
import type { CaseSidebarMenu } from 'types/CaseSidebarMenu'
import { TypePersonne } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarProps {
  caseId: string;
  handleClose: () => void;
}

const CaseSidebar: React.FC<CaseSidebarProps> = ({
  caseId,
  handleClose,
}) => {
  const authInfo = useAuthInfo()
  const isOnline = useIsOnline()
  const view: HTMLElement | null = document.getElementById('case-sidebar')
  const [ openedMenu, setOpenedMenu ] = useState<CaseSidebarMenu>('main')

  const {
    currentData: workflow = [],
    isFetching: isFetchingWorkflow,
  } = useGetCaseWorkflowQuery(caseId)
  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId)
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentsQuery({ dossier: caseId })
  const {
    currentData: tasks = [],
    isFetching: isFetchingTasks,
  } = useGetTasksQuery(
    {
      dossier: caseId,
      collaborateur: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
    },
    { skip: !authInfo?.currentCollaborateur?.refAnnuaire.refComplete },
  )
  const {
    currentData: internalExchanges = [],
    isFetching: isFetchingInternalExchanges,
  } = useGetInternalExchangesQuery({ dossier: caseId })
  const {
    currentData: notifications = [],
    isFetching: isFetchingNotifications,
  } = useGetNotificationsQuery({ dossier: caseId })
  const {
    currentData: history = [],
    isFetching: isFetchingHistory,
  } = useGetHistoriqueQuery(caseId)

  useEffect(() => {
    setOpenedMenu('main')
  }, [ open ])

  useEffect(() => {
    view?.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    })
  }, [ openedMenu, view ])

  const insuredPerson = useMemo(() => caseInfos?.personnes?.find((person) => person.type === TypePersonne.Assure), [ caseInfos ])

  const importedDocumentList = useLiveQuery(async () => caseId ? await db.documents.where([ 'caseId+imported' ]).equals([ caseId, 'true' ]).toArray() : [], [ caseId ])
  const getIdNumber = (id: string): number => parseInt(id.slice(id.lastIndexOf('-') + 1))
  const documentList = [ ...documents ].sort((a, b) => getIdNumber(b.id || '-0') - getIdNumber(a.id || '-0'))

  if (openedMenu === 'main')
    return (
      <div>
        <CaseSidebarHeader
          title={`Dossier `}
          caseId={caseId}
          handleClose={handleClose}
        />
        <CaseWorkflow
          workflow={workflow}
          isFetching={isFetchingWorkflow}
        />
        <DrawerContent>
          <CaseSidebarPerson
            caseId={caseId}
            caseInfos={caseInfos}
            isFetching={isFetchingCaseInfos}
            insuredPerson={insuredPerson}
            isOnline={isOnline}
          />
          <CaseSidebarTiers
            stakeholders={caseInfos?.personnes}
            isFetching={isFetchingCaseInfos}
            insuredPerson={insuredPerson}
          />
          <CaseSidebarPostIt
            postIt={caseInfos?.postIt}
            isFetching={isFetchingCaseInfos}
          />
          <CaseSidebarMedia
            documents={documentList}
            isFetching={isFetchingDocuments}
            importedDocumentList={importedDocumentList || []}
            documentLimit={8}
            setOpenedMenu={() => setOpenedMenu('media')}
            onClose={handleClose}
            isOnline={isOnline}
          />
          <CaseSidebarTask
            tasks={tasks}
            isFetching={isFetchingTasks}
            taskLimit={3}
            setOpenedMenu={() => setOpenedMenu('task')}
          />
          <CaseSidebarInternalExchange
            exchanges={internalExchanges}
            isFetching={isFetchingInternalExchanges}
            strLenLimit={170}
            setOpenedMenu={() => setOpenedMenu('exchange')}
          />
          <CaseSidebarNotification
            notifications={notifications}
            isFetching={isFetchingNotifications}
            strLenLimit={170}
            setOpenedMenu={() => setOpenedMenu('notification')}
          />
          <CaseSidebarHistory
            items={history}
            isFetching={isFetchingHistory}
            itemLimit={3}
            strLenLimit={110}
            setOpenedMenu={() => setOpenedMenu('history')}
            isOnline={isOnline}
          />
        </DrawerContent>
      </div>
    )

  return (
    <>
      <CaseSidebarHeaderSubMenu
        title={`Dossier `}
        setOpenedMenu={() => setOpenedMenu('main')}
        caseId={caseId}
      />
      {
        openedMenu === 'media' &&
          <CaseSidebarMediaMenu
            documents={documentList}
            isFetching={isFetchingDocuments}
            onClose={handleClose}
            caseId={caseId}
            isOnline={isOnline}
          />
      }
      {
        openedMenu === 'task' &&
          <CaseSidebarTaskMenu
            tasks={tasks}
            isFetching={isFetchingTasks}
            onClose={handleClose}
            caseId={caseId}
            isOnline={isOnline}
          />
      }
      {
        openedMenu === 'exchange' &&
          <CaseSidebarInternalExchangeMenu
            exchanges={internalExchanges}
            isFetching={isFetchingInternalExchanges}
            onClose={handleClose}
            caseId={caseId}
          />
      }
      {
        openedMenu === 'notification' &&
          <CaseSidebarNotificationMenu
            notifications={notifications}
            isFetching={isFetchingNotifications}
          />
      }
      {
        openedMenu === 'history' &&
          <CaseSidebarHistoryMenu
            items={history}
            isFetching={isFetchingHistory}
            isOnline={isOnline}
          />
      }
    </>
  )
}

export default CaseSidebar
