/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { calculateAllCompensations } from 'services/CompensationService'
import { useIsOnline } from 'helpers/hooks/useIsOnline'
import { isApiError } from 'helpers/fetchHelpers'
import { formatApiErrorMessage } from 'helpers/formatApiErrorMessage'
import {
  useGetCaseInfosQuery,
  useGetCaseTravelerQuery,
  usePostCaseTravelerMutation,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { toast } from 'react-toastify'
import ReportClosureModal from './ReportClosureModal/ReportClosureModal'

/* Type imports ------------------------------------------------------------- */
import type { SelectChangeEvent } from '@mui/material'
import { EtatRapport } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type StatusColor = {color: string; backgroundColor: string}
type Color = {[x: string]: StatusColor}

/* Internal variables ------------------------------------------------------- */
const colors: Color = {
  [EtatRapport.Qualification]: {
    color: '#BD2323',
    backgroundColor: '#FCECEC',
  },
  [EtatRapport.Gestion]: {
    color: '#0A145F',
    backgroundColor: '#E0E3FB',
  },
  [EtatRapport.Cloture]: {
    color: '#125A26',
    backgroundColor: '#D5F5DE',
  },
}

/* Styled components -------------------------------------------------------- */
interface EtatRapportColorProps {
  customcolor: string;
  custombackgroundcolor?: string;
}

const SelectColoredArrow = styled(KeyboardArrowDown)<EtatRapportColorProps>`
  color: ${(props) => props.customcolor};
  font-size: 28px;
  margin-left: -30px;
  position: absolute !important;
  right: 0 !important;
  pointer-events: none !important;
`

const ColoredOutlinedInput = styled(OutlinedInput)<EtatRapportColorProps>`
  color: ${(props) => props.customcolor};
  background-color: ${(props) => props.custombackgroundcolor};
  font-weight: bold;
  border: 2px solid ${(props) => props.customcolor};

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &:hover {
    background-color: ${(props) => `${props.custombackgroundcolor}55`};
  }
`

const ColoredButton = styled(Button)<EtatRapportColorProps>`
  color: ${(props) => props.customcolor};
  background-color: ${(props) => props.custombackgroundcolor};
  font-size: 16px;
  border: 2px solid ${(props) => props.customcolor};
  padding: 5px 15px;
  height: 100%;
`

const ColoredMenuItem = styled(MenuItem)<EtatRapportColorProps>`
  color: ${(props) => props.customcolor};
`

const EtatRapportContainer = styled.div`
  margin-left: 20px;

  div {
    height: 38px;
    font-size: 14px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ReportStatusButtonProps {
  caseId: string;
  reportId: string;
  reportStatus: EtatRapport;
}

const ReportStatusButton: React.FC<ReportStatusButtonProps> = ({
  caseId,
  reportId,
  reportStatus,
}) => {
  const isOnline = useIsOnline()
  const [ newStatus, setNewStatus ] = useState<EtatRapport>(reportStatus)
  const [ isClosureModalOpen, setIsClosureModalOpen ] = useState<boolean>(false)

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId, { skip: !caseId })
  const {
    currentData: travelerData,
    isFetching: isFetchingTravelerData,
  } = useGetCaseTravelerQuery(reportId, { skip: !reportId })
  const [ submitTraveller ] = usePostCaseTravelerMutation()

  useEffect(() => {
    setNewStatus(reportStatus)
  }, [ reportStatus ])

  const handleChange = async (event: SelectChangeEvent) => {
    if (event.target.value as EtatRapport !== EtatRapport.Cloture) {
      setNewStatus(event.target.value as EtatRapport)

      if (travelerData) {
        const data = structuredClone(travelerData)

        data.acteurs = data.acteurs.map((person) => {
          return ({
            ...person,
            indemnisation: calculateAllCompensations({
              damages: [
                ...person.pieces?.flatMap((room) => room.dommagesImmobilierEmbellissement) || [],
                ...person.dommagesMobilierDivers || [],
              ],
              isSinapps: caseInfos?.mission.origine?.code === 'SIN',
              oldCompensation: person.indemnisation,
            }),
          })
        })

        await submitTraveller({
          query: { id: reportId, validation: true },
          data: {
            ...data,
            etat: { code: event.target.value as EtatRapport },
          },
        }).then((response) => {
          if (isApiError(response)) {
            if (response.error.errors && 'sauvegarde' in response.error.errors) {
              toast.error(formatApiErrorMessage(response.error))
            } else {
              toast.error("Une erreur est survenue. Essayez d'enregistrer le rapport avec le bouton sur le traveller.")
            }
          }
        }).catch(console.error)
      }
    }
  }

  const handleClose = (isReportClosed?: boolean): void => {
    if (isReportClosed) {
      setNewStatus(EtatRapport.Cloture)
    }
    setIsClosureModalOpen(false)
  }

  const onReportSubmit = (): void => {
    setIsClosureModalOpen(true)
  }

  return (
    <EtatRapportContainer onClick={(e): void => e.stopPropagation()}>
      {
        newStatus === EtatRapport.Cloture ?
          <ColoredButton
            customcolor={colors[newStatus].color}
            custombackgroundcolor={colors[newStatus].backgroundColor}
          >
            Clôturé
          </ColoredButton> :
          <Select
            value={newStatus}
            onChange={handleChange}
            input={
              <ColoredOutlinedInput
                customcolor={colors[newStatus].color}
                custombackgroundcolor={colors[newStatus].backgroundColor}
                size="small"
              />
            }
            IconComponent={(): React.ReactElement => <SelectColoredArrow customcolor={colors[newStatus].color} />}
            disabled={isFetchingCaseInfos || isFetchingTravelerData}
          >
            <ColoredMenuItem
              key={EtatRapport.Qualification}
              value={EtatRapport.Qualification}
              customcolor={colors[EtatRapport.Qualification].color}
            >
              Qualification
            </ColoredMenuItem>
            <ColoredMenuItem
              key={EtatRapport.Gestion}
              value={EtatRapport.Gestion}
              customcolor={colors[EtatRapport.Gestion].color}
            >
              Gestion
            </ColoredMenuItem>
            <ColoredMenuItem
              key={EtatRapport.Cloture}
              value={EtatRapport.Cloture}
              customcolor={colors[EtatRapport.Cloture].color}
              onClick={onReportSubmit}
              disabled={!isOnline}
            >
              Clôture
            </ColoredMenuItem>
          </Select>
      }
      {
        isClosureModalOpen &&
          <ReportClosureModal
            caseId={caseId}
            reportId={reportId}
            handleClose={handleClose}
          />
      }
    </EtatRapportContainer>
  )
}

export default ReportStatusButton
