/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostDocumentsMutation } from 'store/api'
import { db } from 'store/db'
import { useAppDispatch } from 'store/hooks'
import { addCaseRoute } from 'store/slices/travelerSlice'
import { isApiError } from 'helpers/fetchHelpers'
import { useIsOnline } from 'helpers/hooks/useIsOnline'
import { createCaseUui } from 'helpers/uuidUtils'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import DocumentNameEditor from 'components/MediaRenderer/DocumentNameEditor'
import DialogTitle from 'components/Dialog/DialogTitle'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { DocumentDossierCreatePayload } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const attachmentSchema = Yup.object({
  files: Yup.array(Yup.mixed<File>()).required(),
  names: Yup.array(Yup.string().required('Le nom est obligatoire')).required(),
  categories: Yup.array(Yup.string()).required(),
})

type AttachmentRequest = Yup.InferType<typeof attachmentSchema>

/* Styled components -------------------------------------------------------- */
const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

/* Component declaration ---------------------------------------------------- */
interface ImportAttachmentModalProps {
  handleClose: (closeQuickActionsModal?: boolean) => void;
  files: File[];
  setFiles: (files: File[]) => void;
  caseId: string;
}

const ImportAttachmentModal: React.FC<ImportAttachmentModalProps> = ({
  handleClose,
  files,
  setFiles,
  caseId,
}) => {
  const dispatch = useAppDispatch()
  const isOnline = useIsOnline()

  const [
    submitNewDocuments,
  ] = usePostDocumentsMutation()

  const onSubmit = (values: AttachmentRequest, { setSubmitting }: FormikHelpers<AttachmentRequest>): void => {
    const data: DocumentDossierCreatePayload = { requests: []}

    values.files.forEach((file, index) => {
      if (file !== undefined) {
        data.requests?.push({
          fichier: file,
          categorie: values.categories[index] || '',
          nom: values.names[index],
        })
      }
    })

    if (isOnline) {
      submitNewDocuments({ caseId, data }).then((response) => {
        if (!isApiError(response)) {
          handleClose()
        } else {
          toast.error("Une erreur est survenue lors de l'envoi des pièces jointes.")
          setSubmitting(false)
        }
      }).catch(console.error)
    } else {
      data.requests?.forEach((value) => {
        const category = value.categorie || undefined
        const name = value.nom
        const blob = value.fichier
        db.documents.put({ blob, caseId, fileId: createCaseUui(caseId), category, name, imported: 'true' }).then((fileId) => {
          dispatch(addCaseRoute({
            caseId,
            caseRoute: {
              name: `Ajout du document ${value.nom}`,
              route: () => submitNewDocuments({ caseId, data: { requests: [ { fichier: blob, categorie: category, nom: name } ]}}),
              fileId,
            },
          }))
        }).catch(console.error)
      })
      handleClose()
    }
  }

  const formikForm = useForm<AttachmentRequest>(
    {
      initialValues: {
        files: files,
        names: files.map((f) => f.name.split('.')[0]),
        categories: files.map((f) => f.type.split('/')[0] === 'image' ? 'PHO' : ''),
      },
      onSubmit: onSubmit,
      validationSchema: attachmentSchema,
    },
  )

  const handleRemove = (file: File): void => {
    const fileIndex = files.findIndex((f) => f.name === file.name)
    const newNameArray = [ ...formikForm.values.names ]
    const newCategoryArray = [ ...formikForm.values.categories ]
    const newFileArray = [ ...formikForm.values.files ]
    newCategoryArray.splice(fileIndex, 1)
    newNameArray.splice(fileIndex, 1)
    newFileArray.splice(fileIndex, 1)
    formikForm.setFieldValue('names', newNameArray).catch(console.error)
    formikForm.setFieldValue('categories', newCategoryArray).catch(console.error)
    formikForm.setFieldValue('files', newFileArray).catch(console.error)
    setFiles(files.filter((f) => f.name !== file.name))
  }

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      fullWidth
      maxWidth="sm"
    >
      <Form form={formikForm}>
        <DialogTitle>
          Importer
          <CloseButton handleClose={handleClose} />
        </DialogTitle>
        <DialogContentContainer>
          {
            files.map((file, index) => (
              <DocumentNameEditor
                key={`${file.name}-${index}`}
                fieldNames={{ name: `names[${index}]`, category: `categories[${index}]` }}
                file={file}
                category={formikForm.values.categories[index]}
                onRemove={() => handleRemove(file)}
              />
            ))
          }
        </DialogContentContainer>
        <DialogActionContainer>
          <FormButton
            type="submit"
            variant="contained"
            disabled={formikForm.isSubmitting}
          >
            {
              formikForm.isSubmitting ?
                <CircularProgress /> :
                'Importer'
            }
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default ImportAttachmentModal
