/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { openFileInNewWindow } from 'helpers/offlineMediaHelper'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import {
  InsertDriveFileOutlined,
  InsertPhotoOutlined,
} from '@mui/icons-material'

/* Styled components -------------------------------------------------------- */
const DownloadButton = styled(Button)`
  padding: 5px 6px 5px 3px;
  justify-content: left;
  width: fit-content;
  height: 43px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    margin-right: 2px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DownloadFileButtonProps {
  url?: string;
  name: string;
  isPhoto?: boolean;
  isOnline: boolean;
  fileId: string;
}

const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({
  url,
  name,
  isPhoto = false,
  isOnline,
  fileId,
}) => {

  const onClick = () => {
    openFileInNewWindow(isOnline, fileId, url)
  }

  return (
    <DownloadButton
      variant="outlined"
      onClick={onClick}
      disabled={!url}
    >
      {
        isPhoto ?
          <InsertPhotoOutlined /> :
          <InsertDriveFileOutlined />
      }
      {name}
    </DownloadButton>
  )
}

export default DownloadFileButton
