/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogContent,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import DialogTitle from 'components/Dialog/DialogTitle'

/* Type declarations -------------------------------------------------------- */
type StatusColor = {color: string; backgroundColor: string}

/* Styled components -------------------------------------------------------- */
interface ReportObjectiveColorProps {
  customcolor: string;
  custombackgroundcolor?: string;
}

const ColoredButton = styled(Button)<ReportObjectiveColorProps>`
  color: ${(props) => props.customcolor};
  background-color: ${(props) => props.custombackgroundcolor};
  border: 2px solid ${(props) => props.customcolor};
  height: 38px;
  font-size: 14px;
`

const ReportObjectiveContainer = styled.div`
  margin-left: 1px;
  margin-right: 2px;
`

const DialogContentContainer = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const DateContainer = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 1.4rem;
`

const OkButton = styled(Button)`
  width: 120px;
`

/* Component declaration ---------------------------------------------------- */
interface ReportObjectiveButtonProps {
  reportObjective?: string | null;
  company: string;
}

const ReportObjectiveButton: React.FC<ReportObjectiveButtonProps> = ({
  reportObjective,
  company,
}) => {
  const [ open, setOpen ] = useState<boolean>(false)

  const calculateDaysLeft = (): number => {
    const objectif = DateUtils.APIStrToDateOrUndefined(reportObjective)
    if (objectif !== undefined) {
      return Math.round((objectif.getTime() - new Date().getTime()) / (1000 * 3600 * 24))
    }
    return 0
  }

  const getColor = (): StatusColor => {
    const daysLeft = calculateDaysLeft()
    if (daysLeft > 5) {
      return ({
        color: '#125A26',
        backgroundColor: '#D5F5DE',
      })
    }
    if (daysLeft > 0) {
      return ({
        color: '#723205',
        backgroundColor: '#F8DBA3',
      })
    }
    return ({
      color: '#BD2323',
      backgroundColor: '#FCECEC',
    })
  }

  if (DateUtils.APIStrToDateOrUndefined(reportObjective) === undefined) {
    return null
  }

  return (
    <ReportObjectiveContainer onClick={(e): void => e.stopPropagation()}>
      <ColoredButton
        customcolor={getColor().color}
        custombackgroundcolor={getColor().backgroundColor}
        onClick={() => setOpen(true)}
      >
        {`${calculateDaysLeft()}j`}
      </ColoredButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Date charte
          <CloseButton handleClose={() => setOpen(false)} />
        </DialogTitle>
        <DialogContentContainer>
          {`D'après le délai de charte de la compagnie ${company}, le rapport doit être déposé avant le :`}
          <DateContainer>
            {DateUtils.APIStrToLocalDateString(reportObjective)}
          </DateContainer>
          <OkButton
            variant="contained"
            onClick={() => setOpen(false)}
          >
            OK
          </OkButton>
        </DialogContentContainer>
      </Dialog>
    </ReportObjectiveContainer>
  )
}

export default ReportObjectiveButton
