/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import OpenSidebarButton from 'components/IconButtons/OpenSidebarButton/OpenSidebarButton'
import GoToCasePageButton from 'components/IconButtons/GoToCasePageButton/GoToCasePageButton'
import OpenQuickActionsButton from 'components/IconButtons/OpenQuickActionsButton/OpenQuickActionsButton'
import DownloadOfflineCaseButton from 'components/IconButtons/DownloadOfflineCaseButton/DownloadOfflineCaseButton'
import ReportObjectiveButton from 'components/ReportObjectiveButton/ReportObjectiveButton'

/* Styled components -------------------------------------------------------- */
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
  }
`

const CaseButtonContainer = styled.div`
  display: flex;
  margin-left: 3px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseItemButtonsContainerProps {
  disabled?: boolean;
  caseId: string;
  reportObjective?: string | null;
  company: string;
  disableCasePageButton?: boolean;
}

const CaseItemButtonsContainer: React.FC<CaseItemButtonsContainerProps> = ({
  caseId,
  disabled,
  reportObjective,
  company,
  disableCasePageButton = false,
}) => {

  return (
    <ButtonsContainer>
      <CaseButtonContainer>
        <ReportObjectiveButton
          reportObjective={reportObjective}
          company={company}
        />
        <DownloadOfflineCaseButton
          caseId={caseId}
          variant="outlined"
          disabled={disabled}
        />
        <OpenSidebarButton
          caseId={caseId}
          variant="outlined"
          disabled={disabled}
        />
        {
          !disableCasePageButton &&
            <GoToCasePageButton
              caseId={caseId}
              variant="outlined"
              disabled={disabled}
            />
        }
        <OpenQuickActionsButton
          caseId={caseId}
          disabled={disabled}
        />
      </CaseButtonContainer>
    </ButtonsContainer>
  )
}

export default CaseItemButtonsContainer
