/* Type imports ------------------------------------------------------------- */
import type {
  PieceJointe,
  PieceJointeSimplifie,
} from 'API/__generated__/Api'
import { db } from 'store/db'

/* Helper functions --------------------------------------------------------- */
export const openFileInNewWindow = async (isOnline: boolean, fileId: string, url?: string | null) => {
  if (isOnline) {
    window.open(url || '', '_blank', 'noreferrer')
    return
  }

  const file = await db.documents.get({ fileId })

  if (!file?.blob) return

  const blobUrl = URL.createObjectURL(file?.blob)
  window.open(blobUrl, '_blank')
}

export const downloadOfflineDocument = async (document: PieceJointe, caseId: string) => {
  const found = await db.documents.get({ fileId: document.id || '' })
  if (found) return

  const blob = await fetch(document.url || '', { cache: 'force-cache' })
    .then((r) => {
      if (r.status === 200) {
        return r.blob()
      }
      throw Promise.reject(new Error(r.statusText))
    })
    .catch(console.error)

  const blobThumbnail = await fetch(document.urlVignette || '', { cache: 'force-cache' })
    .then((r) => {
      if (r.status === 200) {
        return r.blob()
      }
      throw Promise.reject(new Error(r.statusText))
    })
    .catch(console.error)

  if (blob && blobThumbnail && document.id) {
    await db.documents.put({ blob, blobThumbnail, caseId, fileId: document.id })
  }
}

export const downloadOfflineHistoryDocument = async (document: PieceJointeSimplifie, caseId: string) => {
  const found = await db.documents.get({ fileId: document.libelle || '' })
  if (found) return

  const blob = await fetch(document.url || '', { cache: 'force-cache' })
    .then((r) => {
      if (r.status === 200) {
        return r.blob()
      }
      throw Promise.reject(new Error(r.statusText))
    })
    .catch(console.error)

  if (blob && document.libelle) {
    await db.documents.put({ blob, caseId, fileId: document.libelle })
  }
}
