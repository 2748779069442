/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from 'store/db'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'
import type { FileType } from 'helpers/getMediaFileType'

/* Styled components -------------------------------------------------------- */
interface MediaLibraryContainerProps {
  sheetHeight: number;
}

const MediaLibraryContainer = styled.div<MediaLibraryContainerProps>`
  height: ${(props) => props.sheetHeight}px;
  text-align: center;
  overflow: hidden;
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryDataProps {
  displayType: FileType;
  sheetHeight: number;
  document: PieceJointe;
  isOnline: boolean;
}

const MediaLibraryData: React.FC<MediaLibraryDataProps> = ({
  displayType,
  sheetHeight,
  document,
  isOnline,
}) => {
  const file = useLiveQuery(async () => await db.documents.get({ fileId: document.id || '' }), [ document ])
  const url = useMemo(() => isOnline ? document.url || '' : file?.blob ? URL.createObjectURL(file?.blob) : '', [ isOnline, document, file ])

  return (
    <MediaLibraryContainer sheetHeight={sheetHeight}>
      {
        displayType === 'img' ?
          <img
            src={url}
            alt={document?.fileName}
            height="100%"
          /> :
          displayType === 'pdf' &&
            <iframe
              src={url}
              width="100%"
              height="100%"
              title={document.libelle}
            />
      }
    </MediaLibraryContainer>
  )
}

export default MediaLibraryData
