/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import { useAppSelector } from 'store/hooks'
import { getCaseIsReadyForOffline } from 'store/slices/travelerSlice'

/* Component imports -------------------------------------------------------- */
import {
  ChecklistRtlOutlined,
  BrowserUpdatedOutlined,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import DownloadOfflineActive from './DownloadOfflineActive'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
interface DownloadOfflineCaseButtonProps extends ButtonProps {
  caseId: string;
  disabled?: boolean;
}

const DownloadOfflineCaseButton: React.FC<DownloadOfflineCaseButtonProps> = ({ caseId, disabled = false, ...rest }) => {
  const isReadyForOffline: boolean = useAppSelector(getCaseIsReadyForOffline(caseId))
  const [ active, setActive ] = useState<boolean>(false)

  useEffect(() => {
    if (isReadyForOffline) {
      setActive(false)
    }
  }, [ isReadyForOffline ])

  const activateDownload = useMemo(() => active && <DownloadOfflineActive caseId={caseId} />, [ active ])

  return (
    <>
      <CustomIconButton
        Icon={isReadyForOffline ? ChecklistRtlOutlined : BrowserUpdatedOutlined}
        onClick={(e) => {e.stopPropagation(); setActive(true)}}
        disabled={disabled || active || isReadyForOffline}
        {...rest}
        label="Télécharger pour le mode hors-ligne"
      />
      {activateDownload}
    </>
  )
}

export default DownloadOfflineCaseButton
