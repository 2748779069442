/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { useMediaLibrary } from 'helpers/hooks/useMediaLibrary'
import { getMediaFileType } from 'helpers/getMediaFileType'
import { useIsOnline } from 'helpers/hooks/useIsOnline'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { Sheet } from 'react-modal-sheet'
import MediaLibraryData from './MediaLibraryData/MediaLibraryData'
import MediaLibraryRenameModal from './MediaLibraryRenameModal/MediaLibraryRenameModal'

/* Type imports ------------------------------------------------------------- */
import type { FileType } from 'helpers/getMediaFileType'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import { Close } from '@mui/icons-material'

/* Styled components -------------------------------------------------------- */
const BottomSheet = styled(Sheet)`
  z-index: 205 !important;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0px 20px 5px;
  margin-top: -15px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryBottomSheetProps {}

const MediaLibraryBottomSheet: React.FC<MediaLibraryBottomSheetProps> = () => {
  const navigate = useNavigate()
  const library = useMediaLibrary()
  const isOnline = useIsOnline()
  const [ openRenameModal, setOpenRenameModal ] = useState<boolean>(false)
  const [ contentHeight, setContentHeight ] = useState<number>(0.75)
  const snapPoints = [ 0.95, 0.75, 0.5, 70 ]

  const displayType: FileType = useMemo(() => {
    if (!library.state.document) {
      return 'img'
    }

    return getMediaFileType(library.state.document)
  }, [ library.state.document ])

  if (!library.state.document) {
    return null
  }

  const handleClose = () => {
    library.closeMediaLibrary()
  }

  const handleEdit = () => {
    handleClose()
    navigate(`/mediatheque/${library.state.document?.id}`)
  }

  const handleRename = () => {
    setOpenRenameModal(true)
  }

  const calculateHeight = () => {
    setTimeout(() => {
      const container = document.getElementsByClassName('media-sheet-content')[0].getBoundingClientRect().y
      setContentHeight(window.innerHeight - container)
    }, 200)
  }

  return (
    <BottomSheet
      isOpen
      onClose={handleClose}
      snapPoints={snapPoints}
      dragVelocityThreshold={5000}
      initialSnap={1}
      onSnap={calculateHeight}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Header>
          <HeaderContainer>
            {library.state.document?.libelle || 'Sans nom'}
            <ButtonsContainer>
              <Button
                variant="contained"
                onClick={handleEdit}
                disabled={!isOnline}
              >
                Annoter
              </Button>
              <Button
                variant="contained"
                onClick={handleRename}
              >
                Modifier
              </Button>
              <CustomIconButton
                Icon={Close}
                label="Fermer"
                onClick={handleClose}
              />
            </ButtonsContainer>
          </HeaderContainer>
        </Sheet.Header>
        <Sheet.Content className="media-sheet-content">
          <MediaLibraryData
            displayType={displayType}
            sheetHeight={contentHeight}
            document={library.state.document}
            isOnline={isOnline}
          />
          {
            openRenameModal &&
              <MediaLibraryRenameModal
                document={library.state.document}
                handleClose={() => setOpenRenameModal(false)}
              />
          }
        </Sheet.Content>
      </Sheet.Container>
    </BottomSheet>
  )
}

export default MediaLibraryBottomSheet
