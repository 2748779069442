/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { db } from 'store/db'
import {
  CaseRouteStatus,
  updateCaseRoute,
} from 'store/slices/travelerSlice'
import { useAppDispatch } from 'store/hooks'
import { isApiError } from 'helpers/fetchHelpers'
import { formatApiErrorMessage } from 'helpers/formatApiErrorMessage'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { SubdirectoryArrowRightRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type imports ------------------------------------------------------------- */
import type { CaseRoute } from 'store/slices/travelerSlice'

/* Styled components -------------------------------------------------------- */
const ReportTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  padding-top: 10px;
`

const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
`

const SubIcon = styled(SubdirectoryArrowRightRounded)`
  color: ${(props) => props.theme.palette.secondary.main};
  top: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface OfflineDataManagerRouteProps {
  caseId: string;
  caseRoute: CaseRoute;
  isOnline: boolean;
}

const OfflineDataManagerRoute: React.FC<OfflineDataManagerRouteProps> = ({
  caseRoute,
  isOnline,
  caseId,
}) => {
  const dispatch = useAppDispatch()

  const executePromise = async () => {
    await caseRoute.route().then((response) => {
      if (isApiError(response)) {
        toast.error(`Une erreur est survenue sur ${caseRoute.name} : ${formatApiErrorMessage(response.error)}`)
        dispatch(updateCaseRoute({ caseId, id: caseRoute.id, status: CaseRouteStatus.Error }))
      } else {
        dispatch(updateCaseRoute({ caseId, id: caseRoute.id, status: CaseRouteStatus.Fulfilled }))
        if (caseRoute.fileId) {
          db.documents.delete(caseRoute.fileId)
        }
      }
    })
  }

  const buttonStatusColor = caseRoute.status === CaseRouteStatus.Fulfilled ? 'success' : caseRoute.status === CaseRouteStatus.Error ? 'error' : 'primary'
  const chipStatusColor = caseRoute.status === CaseRouteStatus.Fulfilled ? 'green' : caseRoute.status === CaseRouteStatus.Error ? 'red' : 'orange'
  const chipStatusText = caseRoute.status === CaseRouteStatus.Fulfilled ? 'Validé' : caseRoute.status === CaseRouteStatus.Error ? 'Erreur' : 'En attente'

  return (
    <ReportTitle>
      <TitleIconContainer>
        <SubIcon />
        {caseRoute.name}
        <ColoredSquareChip color={chipStatusColor}>
          {chipStatusText}
        </ColoredSquareChip>
      </TitleIconContainer>
      <ButtonsContainer>
        <Button
          onClick={executePromise}
          disabled={!isOnline || caseRoute.status === CaseRouteStatus.Fulfilled}
          variant="outlined"
          color={buttonStatusColor}
        >
          Executer
        </Button>
      </ButtonsContainer>
    </ReportTitle>
  )
}

export default OfflineDataManagerRoute
