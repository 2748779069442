/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { store } from 'store/store'
import {
  setAPIHealthStatus,
  setIsOnline,
} from 'store/slices/networkSlice'
import { runPendingActions } from 'store/helper/stackAction'

/* Type imports ------------------------------------------------------------- */
import { HealthStatus } from 'API/__generated__/Api'

/* Internal variables ------------------------------------------------------- */
const CONNECTIVITY_SERVICE_FETCH_INTERVAL_MS = 10000

let fetchInterval: NodeJS.Timeout | null = null

/* ConnectivityService methods ---------------------------------------------- */
export const start = (): void => {
  if (window !== null) {
    window.addEventListener('online', () => {
      store.dispatch(setIsOnline(true))

      console.log('[INFO] <ConnectivityService> The application is now online')
      runPendingActions().catch((err) => console.error(err))
      fetchInterval = setInterval(() => {
        OpenAPI.health.statusHealthList()
          .then(
            (response) => {
              const healthCheckResult = response.data.status
              if (healthCheckResult) {
                setAPIHealthStatus(healthCheckResult)
              }
            },
          ).catch((error) => {
            setAPIHealthStatus(HealthStatus.Unhealthy)
            console.error(`[ERROR] <ConnectivityService> Error on fetching API health check : ${JSON.stringify(error)}`)
          })
      },
      CONNECTIVITY_SERVICE_FETCH_INTERVAL_MS,
      )
    })

    window.addEventListener('offline', () => {
      store.dispatch(setIsOnline(false))

      if (fetchInterval !== null) {
        clearInterval(fetchInterval)
        fetchInterval = null
      }

      console.log('[INFO] <ConnectivityService> The application is now offline')
    })
  } else {
    console.log('Window is null')
  }
}
