/* Framework imports -------------------------------------------------------- */
import { useMemo } from 'react'
import * as Redux from 'react-redux'

/* Module imports ----------------------------------------------------------- */
import {
  persistor,
  store,
} from 'store/store'
import { getAuthFromLocalStorage } from 'helpers/localStorageHelpers'
import {
  setAuthInfo,
  getAuthState,
  resetAuthInfo,
} from './slices/authSlice'
import {
  getCaseReportSummaryList,
  getCaseSelectedReport,
} from './slices/travelerSlice'

/* Components imports ------------------------------------------------------- */
import { toast } from 'react-toastify'

/* Type imports ------------------------------------------------------------- */
import type {
  RootState,
  AppDispatch,
} from 'store/store'
import type {
  LoginResponse,
  RapportResume,
} from 'API/__generated__/Api'
import type { Nullable } from 'types/Nullable'

/* Generic Redux store hooks ------------------------------------------------ */
export const useAppDispatch: () => AppDispatch = Redux.useDispatch
export const useAppSelector: Redux.TypedUseSelectorHook<RootState> = Redux.useSelector

/* Specific Redux store hooks ----------------------------------------------- */
export const useAuthInfo = (): Nullable<LoginResponse> | null => {
  const dispatch = useAppDispatch()

  const lLoginInfo: Nullable<LoginResponse> = useAppSelector(
    getAuthState,
    (pLeft: Nullable<LoginResponse>, pRight: Nullable<LoginResponse>): boolean => {
      return pLeft.currentCollaborateur?.id === pRight.currentCollaborateur?.id &&
        pLeft.token === pRight.token
    },
  )

  return useMemo<Nullable<LoginResponse> | null>((): Nullable<LoginResponse> | null => {
    if (!lLoginInfo.token) {
      const lLocalStorageAuthInfo = getAuthFromLocalStorage()

      if (lLocalStorageAuthInfo !== null) {
        dispatch(setAuthInfo(lLocalStorageAuthInfo))
        return lLocalStorageAuthInfo
      }

      return null
    }
    if (lLoginInfo.token === 'null') {
      return null
    }

    return lLoginInfo
  }, [ lLoginInfo, dispatch ])
}

export const onLogoutReset = (force: boolean = false) => {
  if (force) {
    toast.warn('Merci de vous reconnecter', { toastId: 'logout' })
  }
  persistor.pause()
  persistor.flush().then(() => {return persistor.purge()}).catch(console.error)
  store.dispatch(resetAuthInfo())
}

export const useSelectedReport = (caseId: string): Nullable<string> | null => {
  const dispatch = useAppDispatch()

  const selectedReport: Nullable<string> | null = useAppSelector(
    getCaseSelectedReport(caseId),
    (pLeft: Nullable<string> | null, pRight: Nullable<string> | null): boolean => pLeft === pRight,
  )

  return useMemo<Nullable<string> | null>((): Nullable<string> | null => selectedReport, [ selectedReport, dispatch ])
}

export const useReports = (caseId: string): RapportResume[] => {
  const dispatch = useAppDispatch()

  const reports: RapportResume[] = useAppSelector(
    getCaseReportSummaryList(caseId),
    (pLeft: RapportResume[], pRight: RapportResume[]): boolean => {
      if (pLeft.length !== pRight.length) return false
      return pLeft.every((report, index) => JSON.stringify(report) === JSON.stringify(pRight[index]))
    },
  )

  return useMemo<RapportResume[] >((): RapportResume[] => reports, [ reports, dispatch ])
}
