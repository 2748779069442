/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import Search from '@mui/icons-material/SearchRounded'
import CaseSearcherModal from './CaseSearcherModal'

/* Styled components -------------------------------------------------------- */
const SearchIcon = styled(Search)`
  font-size: 26px !important;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSearcherButtonProps {
  disabled?: boolean;
}

const CaseSearcherButton: React.FC<CaseSearcherButtonProps> = ({ disabled }) => {
  const [ openModal, setOpenModal ] = useState<boolean>(false)

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<SearchIcon />}
        onClick={() => setOpenModal(true)}
        disabled={disabled}
      >
        Rechercher un dossier
      </Button>
      {openModal && <CaseSearcherModal handleClose={() => setOpenModal(false)} />}
    </>
  )
}

export default CaseSearcherButton
