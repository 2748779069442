/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'
import { isValidString } from 'helpers/isValidString'
import { handleNumberVerification } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithBackArrowTwoSmallButtons from 'layouts/MainLayout/Headers/HeaderWithBackArrowTwoSmallButtons'
import HeaderTravelerAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderTravelerAction'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'

/* Type imports ------------------------------------------------------------- */
import {
  ActionRecours,
  TypePersonne,
} from 'API/__generated__/Api'
import type {
  CibleRecours,
  ActeurTraveller,
  RecoursTraveller,
} from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { TravelerForm } from 'types/TravelerForm'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;
`

interface ErrorField {
  error?: boolean;
}

const TwoGridContainer = styled(GridContainer)<ErrorField>`
  grid-template-columns: repeat(2, 1fr);
  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
  }
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;
`

const FormContainer = styled.div`
  padding-bottom: 40px;
`

const BigCheckableButton = styled(CheckableButton)`
  height: 100%;
`

const NameContainer = styled.div`
  font-weight: bolder;
  font-size: .9rem;
`

const DescriptionContainer = styled.div`
  font-size: .9rem;
`

/* Component declaration ---------------------------------------------------- */
interface AddRecoursePageProps {}

const AddRecoursePage: React.FC<AddRecoursePageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ recourseTargetList, setRecourseTargetList ] = useState<CibleRecours[]>([])

  useEffect(() => {
    formikForm.resetForm({
      values: {
        ...formikForm.values,
        newRecourse: formikForm.initialValues.newRecourse,
      },
    })
  }, [])

  useEffect(() => {
    if (formikForm.values.acteurs !== undefined && formikForm.values.acteurs[0].id !== undefined) {
      formikForm.setFieldValue('newRecourse.acteurId', formikForm.values.acteurs[0].id)
      const targets: CibleRecours[] = formikForm.values.acteurs
        .filter((tiers) => tiers.id !== formikForm.values.acteurs.find((acteur) => acteur.type === TypePersonne.Assure)?.id && !tiers.isDeleted)
        .map((person) => ({
          id: person.id,
          nom: person.nom,
          police: person.police || '',
          assureurCible: person.intervenants?.find((stakeholder) => stakeholder.mandant && !stakeholder.isDeleted) ?
            {
              id: '',
              ...person.intervenants?.find((stakeholder) => stakeholder.mandant && !stakeholder.isDeleted),
              refExpert: person.intervenants?.find((stakeholder) => stakeholder.qualite?.code === 'ET' && !stakeholder.isDeleted)?.refExpert,
              cabinet: person.intervenants?.find((stakeholder) => stakeholder.qualite?.code === 'ET' && !stakeholder.isDeleted)?.cabinet,
            } :
            undefined,
        }))
      setRecourseTargetList(targets)
    }
  }, [ formikForm.values.acteurs ])

  const recourseNumber = useMemo(() => formikForm.values.acteurs?.filter((person) => !person.isDeleted)?.flatMap((person) => person.recours).length || 0, [ formikForm.values.acteurs ])

  const handleValue = (type: string, value?: string | boolean | string[] | number): void => {
    formikForm.setFieldValue(`newRecourse.${type}`, value)
  }

  const addNewRecourse = (goBack: boolean = false): void => {
    console.log('values', formikForm.values.newRecourse)
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (errors.newRecourse === undefined) {
          const acteurs: ActeurTraveller[] = structuredClone(formikForm.values.acteurs)
          const newRecourse = formikForm.values.newRecourse
          const createdRecourse: RecoursTraveller = {
            ...newRecourse,
            compteur: (acteurs.find((acteur) => acteur.id === newRecourse.cibleRecours.id)?.recours?.length || 0) + 1,
            cibleRecours: recourseTargetList.find((cible) => cible.id === newRecourse.cibleRecours.id) || {
              id: '',
              nom: '',
              police: '',
              assureurCible: {
                id: '',
                nom: '',
                cabinet: '',
                refExpert: '',
              },
            },
          }
          acteurs.find((acteur) => acteur.id === newRecourse.cibleRecours.id)?.recours?.push(createdRecourse)
          formikForm.resetForm({
            values: {
              ...formikForm.values,
              acteurs,
              newRecourse: formikForm.initialValues.newRecourse,
            },
          })
          if (goBack) {
            navigate(`/dossiers/${caseId}/traveller/recours`)
          }
        } else {
          console.log('errors from newRecourse', errors.newRecourse)
        }
      })
      .catch(console.error)
  }

  const typeOptions: SegmentedButtonOption<string>[] = enumToSegmentedButtonOptions(ActionRecours)
  const aSubirOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Subir' }, { value: false, label: 'Contre' } ]
  const neufOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'A neuf' }, { value: false, label: 'Vétusté déduite' } ]

  return (
    <>
      <HeaderWithBackArrowTwoSmallButtons
        title="Ajout de recours"
        caseId={caseId}
        onClickBack={() => navigate(`/dossiers/${caseId}/traveller/recours`)}
      />
      <HeaderTravelerAction
        title={`${recourseNumber} recours ajouté${recourseNumber > 1 ? 's' : ''}`}
      >
        <SubmitFormButton
          variant="outlined"
          onClick={() => addNewRecourse(true)}
          disabled={formikForm.values.disabled}
        >
          Valider et terminer
        </SubmitFormButton>
        <SubmitFormButton
          variant="contained"
          onClick={() => addNewRecourse(false)}
          disabled={formikForm.values.disabled}
        >
          Valider et ajouter nouveau
        </SubmitFormButton>
      </HeaderTravelerAction>
      <PageContainer>
        {
          formikForm.values.acteurs !== undefined &&
            <FormContainer>
              <TravelerLargeTitle>
                {`Recours ${recourseNumber + 1}`}
              </TravelerLargeTitle>
              <FormBoldTitle>
                Recours / Action
              </FormBoldTitle>
              <SegmentedButtons
                options={typeOptions}
                selectedOption={formikForm.values.newRecourse.type}
                setSelectedOption={(newVal) => handleValue('type', newVal)}
              />
              <FormBoldTitle>
                Contre / À subir
              </FormBoldTitle>
              <SegmentedButtons
                options={aSubirOptions}
                selectedOption={formikForm.values.newRecourse.aSubir}
                setSelectedOption={(newVal) => handleValue('aSubir', newVal)}
              />
              <FormBoldTitle>
                Type de prestation
              </FormBoldTitle>
              <SegmentedButtons
                options={neufOptions}
                selectedOption={formikForm.values.newRecourse.neuf}
                setSelectedOption={(newVal) => handleValue('neuf', newVal)}
              />
              <FormBoldTitle required>
                Assureur tiers
              </FormBoldTitle>
              <TwoGridContainer error={formikForm.touched.newRecourse?.cibleRecours !== undefined && formikForm.errors.newRecourse?.cibleRecours !== undefined}>
                {
                  recourseTargetList
                    .map((value: CibleRecours, index) => (
                      <BigCheckableButton
                        key={`${value.id}-${index}`}
                        checked={formikForm.values.newRecourse.cibleRecours.id === value.id}
                        onChange={() => handleValue('cibleRecours.id', value.id)}
                        type="radio"
                        label={
                          (
                            <>
                              <NameContainer>
                                {isValidString(value.assureurCible?.nom) ? value.assureurCible?.nom : value.nom}
                              </NameContainer>
                              <DescriptionContainer>
                                <div>
                                  {isValidString(value.police) && `N° Police ${value.police}`}
                                </div>
                                <div>
                                  {isValidString(value.assureurCible?.cabinet) && `Cabinet d'expertise ${value.assureurCible?.cabinet}`}
                                </div>
                                <div>
                                  {isValidString(value.assureurCible?.refExpert) && `Ref. expert ${value.assureurCible?.refExpert}`}
                                </div>
                              </DescriptionContainer>
                            </>
                          )
                        }
                      />
                    ))
                }
              </TwoGridContainer>
              <ErrorMessage name="newRecourse.cibleRecours.id" />
              <FormBoldTitle>
                Montant
              </FormBoldTitle>
              <PriceField
                name="newRecourse.montant"
                value={formikForm.values.newRecourse.montant || 0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValue('montant', handleNumberVerification(e.target.value, 2))}
              />
              <FormBoldTitle>
                Motif
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Votre message"
                name="newRecourse.motif"
                rows={5}
                multiline
              />
            </FormContainer>
        }
      </PageContainer>
    </>
  )
}

export default AddRecoursePage
