/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogContent,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import DialogTitle from 'components/Dialog/DialogTitle'
import OfflineDataManagerCase from './OfflineDataManagerCase'

/* Type imports ------------------------------------------------------------- */
import type { TravelerState } from 'store/slices/travelerSlice'

/* Component declaration ---------------------------------------------------- */
interface OfflineDataManagerModalProps {
  handleClose: (edit?: boolean) => void;
  offlinesCases: string[];
  travelerState: TravelerState;
  isOnline: boolean;
}

const OfflineDataManagerModal: React.FC<OfflineDataManagerModalProps> = ({
  handleClose,
  offlinesCases,
  travelerState,
  isOnline,
}) => {

  const handleQuit = (): void => {
    handleClose(false)
  }

  return (
    <Dialog
      open
      onClose={handleQuit}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Mes dossiers hors-ligne
        <CloseButton handleClose={handleQuit} />
      </DialogTitle>
      <DialogContent>
        {
          offlinesCases.map((caseId) => (
            <OfflineDataManagerCase
              key={caseId}
              caseId={caseId}
              travelerCase={travelerState[caseId]}
              isOnline={isOnline}
            />
          ))
        }
      </DialogContent>
    </Dialog>
  )
}

export default OfflineDataManagerModal
