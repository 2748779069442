/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import { SubdirectoryArrowRightRounded } from '@mui/icons-material'
import ReportSyncStatusButton from 'layouts/TravelerLayout/TravelerButton/ReportSyncStatusButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type imports ------------------------------------------------------------- */
import type { TravelerReport } from 'store/slices/travelerSlice'

/* Styled components -------------------------------------------------------- */
const ReportTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  padding-top: 10px;
`

const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
`

const SubIcon = styled(SubdirectoryArrowRightRounded)`
  color: ${(props) => props.theme.palette.secondary.main};
  top: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface OfflineDataManagerReportProps {
  reportId: string;
  travelerReport: TravelerReport;
}

const OfflineDataManagerReport: React.FC<OfflineDataManagerReportProps> = ({
  reportId,
  travelerReport,
}) => {

  return (
    <ReportTitle>
      <TitleIconContainer>
        <SubIcon />
        {`Rapport ${reportId}`}
        <ColoredSquareChip color={!travelerReport.sync.withoutValidation ? 'red' : 'orange'}>
          {!travelerReport.sync.withoutValidation ? 'Non sauvegardé' : `Sauvegardé le ${DateUtils.APIStrToLocalDateString(travelerReport.sync.withoutValidation, { month: 'short', day: 'numeric' })}`}
        </ColoredSquareChip>
      </TitleIconContainer>
      <ButtonsContainer onClick={(e) => e.stopPropagation()}>
        {
          travelerReport.formikForm && travelerReport.errorForm &&
            <ReportSyncStatusButton
              formikForm={travelerReport.formikForm}
              errorForm={travelerReport.errorForm}
            />
        }
      </ButtonsContainer>
    </ReportTitle>
  )
}

export default OfflineDataManagerReport
