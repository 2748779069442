/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Dialog } from '@mui/material'
import DialogTitle from 'components/Dialog/DialogTitle'
import DocumentNameEditorForm from 'components/MediaRenderer/DocumentNameEditorForm'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const StyledDocumentNameEditorForm = styled(DocumentNameEditorForm)`
  margin-top: 10px 20px 0px;
  margin-bottom: 0px;
  border: none;
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryRenameModalProps {
  handleClose: () => void;
  document: PieceJointe;
}

const MediaLibraryRenameModal: React.FC<MediaLibraryRenameModalProps> = ({
  handleClose,
  document,
}) => {

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Renommer / Supprimer
      </DialogTitle>
      <StyledDocumentNameEditorForm
        document={document}
        onEdition={() => null}
      />
    </Dialog>
  )
}

export default MediaLibraryRenameModal
