/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from 'react-filerobot-image-editor'

/* Type imports ------------------------------------------------------------- */
import type { getCurrentImgDataFunction } from 'react-filerobot-image-editor'

/* Type declarations -------------------------------------------------------- */
type cropPresetItem = {
  titleKey: string;
  width?: number;
  height?: number;
  ratio?: string;
  descriptionKey?: string;
  icon?: string | HTMLElement | React.FunctionComponent;
  disableManualResize?: boolean;
}

/* Internal variables ------------------------------------------------------- */
const translations = {
  save: 'Télécharger',
  resize: 'Modifier la taille',
  resizeTab: 'Modifier la taille',
  annotateTab: 'Annoter',
  flipX: 'Retourner horizontalement',
  flipY: 'Retourner verticalement',
  unFlipX: 'Retourner horizontalement',
  unFlipY: 'Retourner verticalement',
}

const defaultSourceUrl: string = 'https://scaleflex.cloudimg.io/v7/demo/river.png'

const presetItems: cropPresetItem[] = [
  {
    titleKey: 'classicTv',
    descriptionKey: '4:3',
    ratio: (4 / 3).toString(),
  },
  {
    titleKey: 'cinemascope',
    descriptionKey: '21:9',
    ratio: (21 / 9).toString(),
  },
]

/* Styled components -------------------------------------------------------- */
const ImageProcessorContainer = styled.div`
  height: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface ImageProcessorProps {
  source: string;
  imgRef: React.RefObject<getCurrentImgDataFunction>;
}

const ImageProcessor: React.FC<ImageProcessorProps> = ({
  source = defaultSourceUrl,
  imgRef,
}) => {
  const downloadBase64File = (base64Data: string, fileName: string): void => {
    const downloadLink = document.createElement('a')
    downloadLink.href = base64Data
    downloadLink.download = fileName
    downloadLink.click()
  }

  return (
    <ImageProcessorContainer>
      {
        source &&
          <FilerobotImageEditor
            getCurrentImgDataFnRef={imgRef}
            previewPixelRatio={0}
            savingPixelRatio={0}
            source={source}
            language="fr"
            translations={translations}
            onSave={(editedImageObject): void => downloadBase64File(editedImageObject.imageBase64 || '', editedImageObject.fullName || editedImageObject.name)}
            annotationsCommon={{ fill: '#ff0000' }}
            Text={{ text: 'Votre texte...' }}
            Rotate={{ angle: 90, componentType: 'slider' }}
            Crop={{ presetsItems: presetItems }}
            tabsIds={[ TABS.ADJUST, TABS.ANNOTATE, TABS.RESIZE ]} // or {['Adjust', 'Annotate', 'Watermark']}
            defaultTabId={TABS.ADJUST} // or 'Annotate'
            defaultToolId={TOOLS.TEXT}
          />
      }
    </ImageProcessorContainer>
  )
}

export default ImageProcessor
