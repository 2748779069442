/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetCaseDocumentsQuery,
  useGetCaseEventDocumentsQuery,
  useGetCaseInfosQuery,
} from 'store/api'
import { useMediaLibrary } from 'helpers/hooks/useMediaLibrary'
import { useIsOnline } from 'helpers/hooks/useIsOnline'
import {
  useReports,
  useAppSelector,
} from 'store/hooks'
import { getRouterLastPath } from 'store/slices/routerHistorySlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CircularProgress,
} from '@mui/material'
import {
  CameraAltOutlined,
  DriveFileRenameOutlineRounded,
  GradingRounded,
  InsertPhotoOutlined,
  PushPinOutlined,
  TopicOutlined,
} from '@mui/icons-material'
import HeaderWithBackArrowTwoSmallButtons from 'layouts/MainLayout/Headers/HeaderWithBackArrowTwoSmallButtons'
import PageContainer from 'layouts/PageContainer/PageContainer'
import TravelerButton from './TravelerButton/TravelerButton'
import ReportSyncStatusButton from './TravelerButton/ReportSyncStatusButton'
import ReportMenu from './ReportMenu/ReportMenu'
import DocumentsModal from './DocumentsModal/DocumentsModal'
import PostItModal from './PostItModal/PostItModal'
import MediaSidebar from './MediaSidebar/MediaSidebar'
import CaseTravelerTabs from './CaseTravelerTabs/CaseTravelerTabs'

/* Type imports ------------------------------------------------------------- */
import type { TravelerButtonProperties } from 'types/TravelerButton'
import type { TravelerForm } from 'types/TravelerForm'

/* Styled components -------------------------------------------------------- */
const TravelerButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 1rem;
  margin-top: .5rem;
  display: flex;
`

const Content = styled.div`
  padding: 30px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.error.main};
  font-weight: bold;
  text-align: center;
`

/* Component declaration ---------------------------------------------------- */
interface TravelerLayoutProps {}

const TravelerLayout: React.FC<TravelerLayoutProps> = () => {
  const { formikForm, errorForm } = useOutletContext<{formikForm: TravelerForm; errorForm: TravelerForm}>()
  const { caseId = '' } = useParams<{ caseId: string }>()
  const reports = useReports(caseId)
  const navigate = useNavigate()
  const isOnline = useIsOnline()
  const mediaLibrary = useMediaLibrary()
  const routerLastPath: string | null = useAppSelector(getRouterLastPath)
  const [ openDocumentsModal, setOpenDocumentsModal ] = useState<boolean>(false)
  const [ openPostItModal, setOpenPostItModal ] = useState<boolean>(false)
  const [ buttons, setButtons ] = useState<TravelerButtonProperties[]>([])

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId)
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentsQuery({ dossier: caseId })
  const {
    currentData: eventDocuments = [],
    isFetching: isFetchingEventDocuments,
  } = useGetCaseEventDocumentsQuery({ dossier: caseId, avecBrouillons: true })

  const isLoading = useMemo(() => isFetchingDocuments || isFetchingCaseInfos || isFetchingEventDocuments, [
    isFetchingDocuments,
    isFetchingCaseInfos,
    isFetchingEventDocuments,
  ])

  useEffect(() => {
    if (!isLoading && caseInfos) {
      setButtons(
        [
          {
            buttonType: 'media',
            icon: InsertPhotoOutlined,
            action: () => mediaLibrary.openMediaSidebar(),
            badge: documents.length,
            variant: 'outlined',
            tooltip: 'Médiathèque',
          },
          {
            buttonType: 'documents',
            icon: TopicOutlined,
            action: () => setOpenDocumentsModal(true),
            badge: eventDocuments.filter((doc) => doc.familleAction?.code === 'LA' || doc.familleAction?.code === 'PV' || doc.familleAction?.code === 'RA').length,
            variant: 'outlined',
            tooltip: 'Documents',
          },
          {
            buttonType: 'postIt',
            icon: PushPinOutlined,
            action: () => setOpenPostItModal(true),
            badge: caseInfos.postIt !== null && caseInfos.postIt !== undefined && caseInfos.postIt !== '' ? 1 : 0,
            variant: 'outlined',
            tooltip: 'Post-it',
          },
          {
            buttonType: 'sinappsReport',
            icon: GradingRounded,
            action: () => navigate(`/dossiers/${caseId}/traveller/actions/compte-rendu`),
            badge: -1,
            variant: 'contained',
            tooltip: 'Compte-rendu',
          },
          {
            buttonType: 'attachment',
            icon: CameraAltOutlined,
            action: () => console.info('clicked on attachment'),
            badge: -1,
            variant: 'contained',
            tooltip: 'Nouvelle pièce-jointe',
          },
          {
            buttonType: 'notepad',
            icon: DriveFileRenameOutlineRounded,
            action: () => navigate(`/dossiers/${caseId}/traveller/actions/bloc-note`),
            badge: -1,
            variant: 'contained',
            tooltip: 'Bloc-note',
          },
        ],
      )
    }
  }, [
    isFetchingDocuments,
    isFetchingCaseInfos,
    isFetchingEventDocuments,
    caseInfos,
    documents,
    eventDocuments,
    caseId,
    navigate,
  ])

  const onGoBack = () => {
    routerLastPath !== null ? navigate(routerLastPath) : navigate(-1)
  }

  return (
    <>
      <HeaderWithBackArrowTwoSmallButtons
        title={`Dossier ${caseId}`}
        caseId={caseId}
        onClickBack={onGoBack}
        reportObjective={formikForm.values?.objectifCharte}
        caseChips={formikForm.values?.infosDossier?.pastilles || []}
        company={formikForm.values?.infosDossier?.compagnie?.libelle || ''}
      />
      <ReportMenu
        caseId={caseId}
        reports={reports}
      >
        <TravelerButtonContainer onClick={(e: React.MouseEvent): void => e.stopPropagation()}>
          {
            buttons.length > 0 ?
              buttons
                .filter((button) => caseInfos?.mission.origine?.code === 'SIN' || button.buttonType !== 'sinappsReport')
                .map((button) => (
                  <TravelerButton
                    key={button.buttonType}
                    caseId={caseId}
                    buttonProperty={button}
                    isOnline={isOnline}
                  />
                )) :
              <CircularProgress />
          }
          {
            buttons.length > 0 && formikForm &&
              <ReportSyncStatusButton
                formikForm={formikForm}
                errorForm={errorForm}
              />
          }
        </TravelerButtonContainer>
      </ReportMenu>
      {
        openDocumentsModal &&
          <DocumentsModal
            handleClose={() => setOpenDocumentsModal(false)}
            disabled={formikForm.values.disabled}
            mandant={formikForm.values?.acteurs?.[0]?.intervenants?.find((stakeholder) => stakeholder.mandant === true)?.id || ''}
          />
      }
      {
        openPostItModal &&
          <PostItModal
            handleClose={() => setOpenPostItModal(false)}
            postIt={caseInfos?.postIt}
          />
      }
      <MediaSidebar />
      <PageContainer>
        {
          reports.length > 0 ?
            <React.Fragment>
              <CaseTravelerTabs
                caseId={caseId}
                irsiData={formikForm.values.irsiData}
                isOnline={isOnline}
              />
              <Outlet context={formikForm} />
            </React.Fragment> :
            isLoading ?
              null :
              <Card>
                <Content>
                  Aucune information disponible pour le moment.
                  <br />
                  Veuillez vérifier que vous avez sélectionné un rapport dans la liste.
                  <br />
                  Si aucun rapport n'est sélectionné, vous pouvez en créer un nouveau en cliquant sur le bouton.
                </Content>
              </Card>
        }
      </PageContainer>
    </>
  )
}

export default TravelerLayout
