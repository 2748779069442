/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'
import { HealthStatus } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface NetworkState {
  isOnline: boolean;
  APIHealthStatus: HealthStatus;
  fetchingRouteList: string[];
}

/* Redux slice -------------------------------------------------------------- */
const networkSlice = createSlice(
  {
    name: 'network',
    initialState: {
      isOnline: navigator.onLine,
      APIHealthStatus: HealthStatus.Healthy,
      fetchingRouteList: [],
    } as NetworkState,
    reducers: {
      setIsOnline: (state, { payload }: PayloadAction<boolean>) => {
        state.isOnline = payload
      },
      setAPIHealthStatus: (state, { payload }: PayloadAction<HealthStatus>) => {
        state.APIHealthStatus = payload
      },
      addFetchingRoute: (state, { payload }: PayloadAction<string>) => {
        if (!state.fetchingRouteList) {
          state.fetchingRouteList = []
        }
        const index = state.fetchingRouteList.indexOf(payload)
        if (index === -1) {
          state.fetchingRouteList.push(payload)
        }
      },
      removeFetchingRoute: (state, { payload }: PayloadAction<string>) => {
        const index = state.fetchingRouteList.indexOf(payload)
        if (index !== -1) {
          state.fetchingRouteList.splice(index, 1)
        }
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const {
  setIsOnline,
  setAPIHealthStatus,
  addFetchingRoute,
  removeFetchingRoute,
} = networkSlice.actions

export default networkSlice.reducer

export const getNetworkState = (state: RootState): NetworkState => {
  return state.network
}
