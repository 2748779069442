/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'

/* Module imports ----------------------------------------------------------- */
import { useIsOnline } from 'helpers/hooks/useIsOnline'
import { useLazyGetCasesByStatusQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
} from '@mui/material'
import CaseSearcherButton from 'components/CaseSearcher/CaseSearcherButton'
import DownloadOfflineActive from 'components/IconButtons/DownloadOfflineCaseButton/DownloadOfflineActive'
import HeaderContainer from './HeadersComponents/HeaderContainer'
import HeaderGroupContainer from './HeadersComponents/HeaderGroupContainer'
import HeaderTitle from './HeadersComponents/HeaderTitle'

/* Type imports ------------------------------------------------------------- */
import { FiltreDossier } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface HeaderWithSearchProps {
  title: string;
  displayDownloadOfflineCasesButton?: boolean;
}

const HeaderWithSearch: React.FC<HeaderWithSearchProps> = ({ title, displayDownloadOfflineCasesButton = false }) => {
  const isOnline = useIsOnline()
  const [
    getCases,
    {
      currentData: caseList = [],
      isFetching: isFetchingCaseList,
    },
  ] = useLazyGetCasesByStatusQuery()

  const onClick = () => {
    getCases({ filtre: FiltreDossier.Jour, startIndex: 0, limit: 100 }).catch(console.error)
  }

  const isDownloaded = useMemo(() => caseList.length > 0, [ caseList ])

  return (
    <HeaderContainer>
      <HeaderTitle title={title} />
      <HeaderGroupContainer>
        {
          displayDownloadOfflineCasesButton && (
            <Button
              variant="contained"
              disabled={isFetchingCaseList || isDownloaded}
              onClick={onClick}
            >
              {isFetchingCaseList ? <CircularProgress size={24} /> : isDownloaded ? 'Dossiers du jour téléchargés' : 'Télécharger les dossiers du jour'}
            </Button>
          )
        }
        <CaseSearcherButton disabled={!isOnline} />
        {
          isDownloaded && caseList.map((c) => (
            <DownloadOfflineActive
              key={c.id}
              caseId={c.id}
            />
          ))
        }
      </HeaderGroupContainer>
    </HeaderContainer>
  )
}

export default HeaderWithSearch
