/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Menu,
  MenuItem,
} from '@mui/material'
import StakeholdersPersonStakeholderLine from './StakeholdersPersonStakeholderLine'
import Stakeholder from './StakeholdersPersonLineComponents'
import StakeholderPersonDeleteModal from './StakeholderPersonDeleteModal'

/* Type imports ------------------------------------------------------------- */
import type { RdvActeur } from 'types/Stakeholders'

/* Styled components -------------------------------------------------------- */

interface StakeholdersPersonLineProps {
  person: RdvActeur;
  separator: boolean;
  handleActeurStatutChange: (acteurId: string, type: string, value: string | boolean) => void;
  handleDelete: (stakeholderId?: string) => void;
  disabled: boolean;
}

/* Component declaration ---------------------------------------------------- */
const StakeholdersPersonLine: React.FC<StakeholdersPersonLineProps> = ({
  person,
  separator,
  handleActeurStatutChange,
  handleDelete,
  disabled,
}) => {
  const navigate = useNavigate()
  const { caseId } = useParams<{ caseId: string }>()
  const [ anchorMenu, setAnchorMenu ] = useState<null | SVGElement>(null)
  const [ deletePerson, setDeletePerson ] = useState<string | null>(null)
  const menuOpen = Boolean(anchorMenu)

  const handleMenuClick = (event: React.MouseEvent<SVGElement>): void => {
    setAnchorMenu(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorMenu(null)
  }

  const handleEdit = (): void => {
    navigate(`/dossiers/${caseId}/traveller/modification/intervenants/${person.acteur.id}`)
    setAnchorMenu(null)
  }

  const handleStakeholderDelete = (remove: boolean = false) => {
    if (
      !disabled &&
      person.acteur.canBeDeleted &&
      !person.intervenants.some((stakeholder) => stakeholder.intervenant.canBeDeleted === false) &&
      (person.acteur.reglements?.length || 0) === 0 &&
      remove
    ) {
      handleDelete()
    }
    setDeletePerson(null)
    handleMenuClose()
  }

  const hasAtLeastOnePhoneNumber = (): boolean => {
    if (isValidString(person.acteur.telBureau) || isValidString(person.acteur.telDomicile) || isValidString(person.acteur.telPortable))
      return true
    return false
  }

  return (
    <div>
      <Stakeholder.GridContainer>
        <div>
          {person.acteur.libelle}
          {isValidString(person.acteur.libelle) && <br />}
          <b>
            {person.acteur.prenom}
            {' '}
            {person.acteur.nom}
            {isValidString(person.acteur.qualite?.libelle) && ' - '}
            {person.acteur.qualite?.libelle}
          </b>
          <Stakeholder.RefPoliceContainer>
            {
              isValidString(person.acteur.police) && (
                <div>
                  {'Police : '}
                  {person.acteur.police}
                </div>
              )
            }
            {
              isValidString(person.acteur.reference) && (
                <div>
                  {'Ref : '}
                  {person.acteur.reference}
                </div>
              )
            }
          </Stakeholder.RefPoliceContainer>
          <Stakeholder.TelContainer>
            {
              hasAtLeastOnePhoneNumber() && (
                <div>
                  {'Tel : '}
                  {
                    isValidString(person.acteur.telBureau) &&
                      <a
                        href={`tel:${person.acteur.telBureau}`}
                        target="_self"
                      >
                        {person.acteur.telBureau}
                      </a>
                  }
                  {' '}
                  {
                    isValidString(person.acteur.telDomicile) &&
                      <a
                        href={`tel:${person.acteur.telDomicile}`}
                        target="_self"
                      >
                        {person.acteur.telDomicile}
                      </a>
                  }
                  {' '}
                  {
                    isValidString(person.acteur.telPortable) &&
                      <a
                        href={`tel:${person.acteur.telPortable}`}
                        target="_self"
                      >
                        {person.acteur.telPortable}
                      </a>
                  }
                </div>
              )
            }
          </Stakeholder.TelContainer>
          {
            person.acteur.mail && (
              <div>
                {'Email : '}
                <a
                  href={`mailto:${person.acteur.mail}`}
                  target="_self"
                >
                  {person.acteur.mail}
                </a>
              </div>
            )
          }
        </div>
        <Stakeholder.ChipContainer>
          <Stakeholder.Chip
            variant={person.statut.convoque ? 'contained' : 'outlined'}
            color="secondary"
            disabled={disabled}
          >
            Convoqué
          </Stakeholder.Chip>
          <Stakeholder.Chip
            variant={person.statut.present ? 'contained' : 'outlined'}
            onClick={() => handleActeurStatutChange(person.statut.acteurId, 'present', !person.statut.present)}
            disabled={disabled}
          >
            Présent
          </Stakeholder.Chip>
        </Stakeholder.ChipContainer>
        <Stakeholder.MoreDotsButton
          variant="text"
          size="small"
        >
          <Stakeholder.MoreDots onClick={handleMenuClick} />
        </Stakeholder.MoreDotsButton>
        <Menu
          anchorEl={anchorMenu}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEdit}>
            {'Voir le détail '}
            {!disabled && '/ Modifier'}
          </MenuItem>
          <MenuItem
            onClick={() => setDeletePerson(person.acteur.nom)}
            disabled={
              disabled ||
              !person.acteur.canBeDeleted ||
              person.intervenants.some((stakeholder) => stakeholder.intervenant.canBeDeleted === false) ||
              (person.acteur.reglements?.length || 0) > 0
            }
          >
            Supprimer
          </MenuItem>
        </Menu>
      </Stakeholder.GridContainer>
      {
        person.intervenants?.filter(((stakeholder) => !stakeholder.intervenant.isDeleted))?.map((stakeholder, stakeholderIndex) => (
          <StakeholdersPersonStakeholderLine
            key={`${stakeholder.intervenant.id}-${stakeholderIndex}`}
            stakeholder={stakeholder}
            handleActeurStatutChange={handleActeurStatutChange}
            handleDelete={() => handleDelete(stakeholder.intervenant.id)}
            recourses={person.acteur.recours?.filter((recourse) => recourse?.cibleRecours?.assureurCible?.id === stakeholder.intervenant.id) || []}
            disabled={disabled}
          />
        ))
      }
      {separator && <Stakeholder.BoldSeparator />}
      {
        deletePerson &&
          <StakeholderPersonDeleteModal
            handleClose={handleStakeholderDelete}
            name={deletePerson}
            recourses={person.acteur.recours || []}
            stakeholders={person.intervenants.filter((stakeholder) => !stakeholder.intervenant.isDeleted)}
            damages={
              [
                ...person.acteur.pieces?.flatMap((room) => room.dommagesImmobilierEmbellissement) || [],
                ...person.acteur.dommagesMobilierDivers || [],
              ]
            }
          />
      }
    </div>
  )
}

export default StakeholdersPersonLine
