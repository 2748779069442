/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetInternalExchangesMessageTypeListQuery,
  useGetInternalExchangesRecipientListQuery,
  usePostNewInternalExchangeMutation,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { useIsOnline } from 'helpers/hooks/useIsOnline'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import {
  TextField,
  Select,
} from 'formik-mui'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { CodeLabel } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const internalExchangeSchema = Yup.object({
  destinataires: Yup.mixed<CodeLabel>().test(
    'is-not-empty',
    'Le destinataire est obligatoire',
    (value) => isValidString(value?.code),
  ).required('Le destinataire est obligatoire'),
  objet: Yup.string().required("L'objet est obligatoire"),
  message: Yup.string().required('Le message est obligatoire'),
  messageType: Yup.string(),
})

type InternalExchangeRequest = Yup.InferType<typeof internalExchangeSchema>

/* Component declaration ---------------------------------------------------- */
interface QaInternalExchangePageProps {}

const QaInternalExchangePage: React.FC<QaInternalExchangePageProps> = () => {
  const isOnline = useIsOnline()
  const { caseId = '' } = useParams<{caseId: string}>()

  const {
    currentData: recipients = [],
    isFetching: isFetchingRecipientList,
  } = useGetInternalExchangesRecipientListQuery(
    {
      dossier: caseId,
      assistante: true,
      expert: true,
    },
  )

  const {
    currentData: messageTypeList = [],
    isFetching: isFetchingMessageTypeList,
  } = useGetInternalExchangesMessageTypeListQuery()

  const [
    submitNewInternalExchange,
  ] = usePostNewInternalExchangeMutation()

  const onSubmit = async (values: InternalExchangeRequest, { setSubmitting, resetForm }: FormikHelpers<InternalExchangeRequest>): Promise<void> => {
    const response = await submitNewInternalExchange({
      caseId,
      data: {
        ...values,
        destinataires: [ values.destinataires.code ],
      },
    })

    if (!isApiError(response)) {
      resetForm()
    } else {
      toast.error("Une erreur est survenue lors de l'envoi de l'échange interne.")
      setSubmitting(false)
    }
  }

  const formikForm = useForm<InternalExchangeRequest>(
    {
      initialValues: {
        destinataires: { code: '', libelle: '' },
        objet: '',
        message: '',
        messageType: '',
      },
      onSubmit: onSubmit,
      validationSchema: internalExchangeSchema,
    },
  )

  useEffect(() => {
    formikForm.setFieldValue('message', messageTypeList.find((message) => message.code === formikForm.values.messageType)?.libelle || '').catch(console.error)
  }, [ formikForm.values.messageType ])

  const recipientList: CodeLabel[] = useMemo((): CodeLabel[] => [ ...recipients ].sort((a: CodeLabel, b: CodeLabel): number => (a.libelle || '').localeCompare(b.libelle || '')), [ recipients ])

  const isLoading = useMemo(() => isFetchingMessageTypeList || isFetchingRecipientList || formikForm.isSubmitting, [ isFetchingMessageTypeList, isFetchingRecipientList, formikForm.isSubmitting ])

  return (
    <>
      <HeaderAction
        title="Nouvel Échange Interne"
        onSubmit={formikForm.handleSubmit}
      >
        <SubmitFormButton
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Envoyer
        </SubmitFormButton>
      </HeaderAction>
      <PageContainer>
        {isLoading && <Loader />}
        <Form form={formikForm}>
          <FormBoldTitle required>
            Destinataire
          </FormBoldTitle>
          <AutocompleteField
            name="destinataires"
            options={recipientList}
          />
          <FormBoldTitle>
            Message type
          </FormBoldTitle>
          <Field
            name="messageType"
            component={Select}
            displayEmpty
            renderValue={verifySelectFieldValue(formikForm.values.messageType)}
            disabled={isFetchingMessageTypeList || !isOnline}
          >
            {
              messageTypeList.map((messageType, index) => (
                <MenuItem
                  value={messageType.code}
                  key={`${messageType.code}-${index}`}
                >
                  {messageType.libelle}
                </MenuItem>
              ))
            }
          </Field>
          <FormBoldTitle required>
            Objet
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Objet"
            name="objet"
          />
          <FormBoldTitle required>
            Message
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Votre message"
            rows={8}
            multiline
            name="message"
          />
        </Form>
      </PageContainer>
    </>
  )
}

export default QaInternalExchangePage
