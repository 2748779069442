/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
const DrawerContent = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: #F6F8FD;
  min-height: calc(100vh - 170px);
  width: 600px;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`

export default DrawerContent
