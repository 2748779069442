/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  useGetDocumentQuery,
  useUpdateDocumentMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import HeaderWithBackArrow from 'layouts/MainLayout/Headers/HeaderWithBackArrow'
import PageContainer from 'layouts/PageContainer/PageContainer'
import ImageProcessor from 'components/MediaProcessors/ImageProcessor/ImageProcessor'
import MediaProcessorFallback from 'components/MediaProcessors/MediaProcessorFallback/MediaProcessorFallback'

/* Type imports ------------------------------------------------------------- */
import type { PdfViewerComponent } from '@syncfusion/ej2-react-pdfviewer'
import type { getCurrentImgDataFunction } from 'react-filerobot-image-editor'

/* Lazy component imports --------------------------------------------------- */
const PdfProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/PdfProcessor/PdfProcessor' /* webpackChunkName: "pdf" */))

/* Styled components -------------------------------------------------------- */
const StyledButton = styled(Button)`
  margin-left: auto;
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryAnnotatePageProps {}

const MediaLibraryAnnotatePage: React.FC<MediaLibraryAnnotatePageProps> = () => {
  const navigate = useNavigate()
  const { mediaId } = useParams<{mediaId: string}>()
  const [ source, setSource ] = useState<{url: string; type: string}>({ url: '', type: '' })
  const [ pdfRef, setPdfRef ] = useState<PdfViewerComponent | null>(null)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const imgRef = useRef<getCurrentImgDataFunction>(null)

  const {
    currentData: media,
    isFetching: isFetchingDocument,
  } = useGetDocumentQuery(mediaId || '')
  const [
    updateDocument,
    { isLoading: isUpdatingDocument },
  ] = useUpdateDocumentMutation()

  useEffect(() => {
    if (!media || isFetchingDocument) {
      return
    }

    const fileType = media.fileType.split('/')
    let type = ''

    if (fileType[0] === 'image') {
      type = 'img'
    }
    else if (fileType[1] === 'msword') {
      type = 'word'
    }
    else if (fileType[1] === 'pdf') {
      type = 'pdf'
    }

    setSource({ url: media.url || '', type })
  }, [ media, isFetchingDocument ])

  const onSubmit = async () => {
    const dataUrlToFile = (dataUrl: string) => {
      const [ header, base64Data ] = dataUrl.split(',')
      const mimeMatch = header.match(/data:(.*?);base64/)
      if (!mimeMatch) {
        throw new Error('Invalid Base64 : cannot extract MIME type.')
      }
      const mimeType = mimeMatch[1]
      const binary = atob(base64Data)
      const arrayBuffer = new Uint8Array(binary.length)

      for (let i = 0; i < binary.length; i++) {
        arrayBuffer[i] = binary.charCodeAt(i)
      }
      return new File([ arrayBuffer ], media?.fileName || 'image.png', { type: mimeType })
    }

    if (!mediaId) return

    let file: File | null = null
    setIsLoading(true)

    if (source.type === 'pdf') {
      const blob = await pdfRef?.saveAsBlob()
      if (blob) {
        file = new File([ blob ], media?.fileName || 'document.pdf')
      }
    }

    if (typeof imgRef.current === 'function') {
      const base64 = imgRef.current({}).imageData.imageBase64
      file = dataUrlToFile(base64 || '')
    }

    if (file) {
      const response = await updateDocument({ id: mediaId, data: { Fichier: file, Nom: file.name }})
      if (isApiError(response)) {
        toast.error(`Une erreur est survenue lors de l'enregistrement ${source.type === 'pdf' ? 'du document' : "de l'image"}.`)
      } else {
        toast.success(source.type === 'pdf' ? 'Le document à bien été modifié.' : "L'image a bien été modifiée.")
        navigate(-1)
      }
    } else {
      navigate(-1)
    }
    setIsLoading(false)
  }

  return (
    <>
      <HeaderWithBackArrow
        onClick={() => navigate(-1)}
        title={`Editer un${source.type === 'pdf' ? ' document' : 'e image'}`}
      >
        <StyledButton
          variant="contained"
          disabled={isUpdatingDocument || isLoading}
          onClick={onSubmit}
        >
          Valider
        </StyledButton>
      </HeaderWithBackArrow>
      <PageContainer>
        {
          source.type === 'pdf' && source.url &&
            <MediaProcessorFallback mediaType="pdf">
              <PdfProcessor
                documentPath={source.url}
                setPdfRef={setPdfRef}
                pdfRef={pdfRef}
              />
            </MediaProcessorFallback>
        }
        {
          source.type === 'img' &&
            <ImageProcessor
              source={source.url}
              imgRef={imgRef}
            />
        }
      </PageContainer>
    </>
  )
}

export default MediaLibraryAnnotatePage
