/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import DialogTitle from 'components/Dialog/DialogTitle'

/* Styled components -------------------------------------------------------- */
const DialogContentContainer = styled(DialogContent)`
  margin: 20px 0px;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

/* Component declaration ---------------------------------------------------- */
interface DocumentConfirmationStatusModalProps {
  handleClose: (isDocumentDefinitive?: boolean) => void;
}

const DocumentConfirmationStatusModal: React.FC<DocumentConfirmationStatusModalProps> = ({ handleClose }) => {

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        Confirmation du statut
        <CloseButton handleClose={handleClose} />
      </DialogTitle>
      <DialogContentContainer>
        Confirmer le document en définitif ?
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton onClick={() => handleClose()}>
          Annuler
        </FormButton>
        <FormButton
          variant="contained"
          type="submit"
          onClick={() => handleClose(true)}
        >
          Valider
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default DocumentConfirmationStatusModal
