/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import DateUtils from 'helpers/DateUtils'
import { useAppDispatch } from 'store/hooks'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import CaseItemContainer from 'components/CasesList/CaseItem/CaseItemContainer/CaseItemContainer'
import CaseItemBarHorizontal from 'components/CasesList/CaseItem/CaseItemBar/CaseItemBarHorizontal'
import CaseItemTitleContainer from './CaseItemTitleContainer/CaseItemTitleContainer'

/* Type imports ------------------------------------------------------------- */
import type {
  DossierRecherche,
  Pastille,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CaseInfoRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 10px;
  align-items: center;
  justify-content: stretch;
  padding: 0.5rem 0px;

  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: minmax(0, 1fr);
  }
`

interface CaseInfoContainerProps {
  addBorder?: boolean;
}

const CaseInfoContainer = styled.div<CaseInfoContainerProps>`
  display: flex;
  justify-content: left;
  padding: 0px 1rem;
  align-items: center;
  border-right: ${(props) => props.addBorder ? `2px solid ${props.theme.colors.grey}` : ''};

  @media ${(props) => props.theme.media.mobile.main} {
    border-right: none;
    border-bottom: ${(props) => props.addBorder ? `2px solid ${props.theme.colors.grey}` : ''};
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseItemProps {
  caseItem: DossierRecherche;
  disabled?: boolean;
  caseChips?: Pastille[];
  isFetchingChip: boolean;
}

const CaseItem: React.FC<CaseItemProps> = ({
  caseItem,
  disabled = false,
  caseChips = [],
  isFetchingChip,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onCaseClick: React.MouseEventHandler<HTMLDivElement> = () => {
    dispatch(setRouterLastPath(window.location.pathname))
    navigate(`/dossiers/${caseItem.id}`)
  }

  return (
    <CaseItemContainer
      onClick={!disabled ? onCaseClick : undefined}
      disabled={disabled}
    >
      <CaseItemTitleContainer
        caseId={caseItem.id}
        assure={`${caseItem.assure.nom} ${caseItem.assure.prenom}`}
        reportObjective={caseItem.objectifCharteRapport}
        company={caseItem.compagnie?.libelle || ''}
        caseChips={caseChips}
        isFetchingChip={isFetchingChip}
        disableCasePageButton
      />
      <CaseItemBarHorizontal />
      <CaseInfoRowContainer>
        <CaseInfoContainer addBorder>
          Rendez-vous :
          <br />
          {
            DateUtils.APIStrToLocalDateString(caseItem.dateRendezVous, {
              hour: 'numeric',
              minute: 'numeric',
            })
          }
        </CaseInfoContainer>
        <CaseInfoContainer addBorder>
          {'Dossier '}
          {caseItem.compagnie?.libelle || 'Compagnie inconnue'}
          <br />
          {caseItem.origine.libelle}
        </CaseInfoContainer>
        <CaseInfoContainer>
          {caseItem.sinistre.nature?.libelle}
          <br />
          {getAddress(caseItem.sinistre.adresse)}
        </CaseInfoContainer>
      </CaseInfoRowContainer>
    </CaseItemContainer>
  )
}

export default CaseItem
