/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useQuickActionModal } from 'helpers/hooks/useQuickActionModal'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogContent,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import DialogTitle from 'components/Dialog/DialogTitle'
import QuickActionsButtons from './QuickActionsButtons'

/* Styled components -------------------------------------------------------- */
const DialogContentContainer = styled(DialogContent)`
  margin-bottom: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface QuickActionsModalProps {}

const QuickActionsModal: React.FC<QuickActionsModalProps> = () => {
  const modal = useQuickActionModal()

  if (!modal.state.caseId) {
    return null
  }

  const handleClose = (): void => {
    modal.closeQuickActions()
  }

  return (
    <Dialog
      open={modal.state.quickActionsOpen}
      onClose={(): { payload: undefined; type: string } => modal.closeQuickActions()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        Actions rapides
        <CloseButton handleClose={handleClose} />
      </DialogTitle>
      <DialogContentContainer>
        <QuickActionsButtons caseId={modal.state.caseId} />
      </DialogContentContainer>
    </Dialog>
  )
}

export default QuickActionsModal
