/* Module imports ----------------------------------------------------------- */
import { v4 as uuid } from 'uuid'

/* uuidUtils  ------------------------------------------ */
export const createStakeholerUuid = () => `0000-4444-${uuid()}`

export const createDamageUuid = () => uuid().replaceAll('-', '')

export const createCaseUui = (caseId: string) => `${caseId}-${uuid()}`

export const isStakeholderUuid = (id: string) => id.startsWith('0000-4444-')
