/* Module imports ----------------------------------------------------------- */
import { useAppSelector } from 'store/hooks'
import { getNetworkState } from 'store/slices/networkSlice'

/* Hook declarations -------------------------------------------------------- */
export const useIsOnline = (): boolean => {
  const networkState = useAppSelector(getNetworkState)

  return networkState.isOnline
}
