/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { isValidString } from 'helpers/isValidString'
import { isStakeholderUuid } from 'helpers/uuidUtils'

/* Component imports -------------------------------------------------------- */
import {
  Menu,
  MenuItem,
  TextField,
} from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Stakeholder from './StakeholdersPersonLineComponents'
import StakeholderPersonDeleteModal from './StakeholderPersonDeleteModal'

/* Type imports ------------------------------------------------------------- */
import type { RecoursTraveller } from 'API/__generated__/Api'
import type { RdvIntervenant } from 'types/Stakeholders'

/* Component declaration ---------------------------------------------------- */
interface StakeholdersPersonStakeholderLineProps {
  stakeholder: RdvIntervenant;
  handleActeurStatutChange: (acteurId: string, type: string, value: string | boolean) => void;
  handleDelete: () => void;
  disabled: boolean;
  recourses: RecoursTraveller[];
}

const StakeholdersPersonStakeholderLine: React.FC<StakeholdersPersonStakeholderLineProps> = ({
  stakeholder,
  handleActeurStatutChange,
  handleDelete,
  disabled,
  recourses,
}) => {
  const navigate = useNavigate()
  const { caseId } = useParams<{ caseId: string }>()
  const [ anchorMenu, setAnchorMenu ] = useState<null | SVGElement>(null)
  const [ deleteStakeholder, setDeleteStakeholder ] = useState<string | null>(null)
  const menuOpen = Boolean(anchorMenu)

  const handleMenuClick = (event: React.MouseEvent<SVGElement>): void => {
    setAnchorMenu(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorMenu(null)
  }

  const handleEdit = (): void => {
    navigate(`/dossiers/${caseId}/traveller/modification/intervenants/${stakeholder.intervenant.id}`)
    setAnchorMenu(null)
  }

  const handleStakeholderDelete = (remove: boolean = false) => {
    if (!disabled && stakeholder.intervenant.canBeDeleted && remove) {
      handleDelete()
    }
    setDeleteStakeholder(null)
    handleMenuClose()
  }

  const hasAtLeastOnePhoneNumber = (): boolean => {
    if (isValidString(stakeholder.intervenant.telBureau) || isValidString(stakeholder.intervenant.telDomicile) || isValidString(stakeholder.intervenant.telPortable))
      return true
    return false
  }

  return (
    <div>
      <Stakeholder.LightSeparator />
      <Stakeholder.GridContainer sub>
        <Stakeholder.SubIcon />
        <div>
          {stakeholder.intervenant.libelle}
          {isValidString(stakeholder.intervenant.libelle) && <br />}
          <b>
            {stakeholder.intervenant.prenom}
            {' '}
            {stakeholder.intervenant.nom}
            {isValidString(stakeholder.intervenant.qualite?.libelle) && ' - '}
            {stakeholder.intervenant.qualite?.libelle}
          </b>
          {
            isValidString(stakeholder.intervenant.reference) && (
              <div>
                {'Ref : '}
                {stakeholder.intervenant.reference}
              </div>
            )
          }
          <Stakeholder.TelContainer>
            {
              hasAtLeastOnePhoneNumber() && (
                <div>
                  {'Tel : '}
                  {
                    isValidString(stakeholder.intervenant.telBureau) &&
                      <a
                        href={`tel:${stakeholder.intervenant.telBureau}`}
                        target="_self"
                      >
                        {stakeholder.intervenant.telBureau}
                      </a>
                  }
                  {' '}
                  {
                    isValidString(stakeholder.intervenant.telDomicile) &&
                      <a
                        href={`tel:${stakeholder.intervenant.telDomicile}`}
                        target="_self"
                      >
                        {stakeholder.intervenant.telDomicile}
                      </a>
                  }
                  {' '}
                  {
                    stakeholder.intervenant.telPortable &&
                      <a
                        href={`tel:${stakeholder.intervenant.telPortable}`}
                        target="_self"
                      >
                        {stakeholder.intervenant.telPortable}
                      </a>
                  }
                </div>
              )
            }
          </Stakeholder.TelContainer>
          {
            stakeholder.intervenant.mail && (
              <div>
                {'Email : '}
                <a
                  href={`mailto:${stakeholder.intervenant.mail}`}
                  target="_self"
                >
                  {stakeholder.intervenant.mail}
                </a>
              </div>
            )
          }
        </div>
        <Stakeholder.ChipContainer>
          <Stakeholder.Chip
            variant={stakeholder.statut.convoque ? 'contained' : 'outlined'}
            color="secondary"
            disabled={disabled}
          >
            Convoqué
          </Stakeholder.Chip>
          <Stakeholder.Chip
            variant={stakeholder.statut.present ? 'contained' : 'outlined'}
            onClick={() => handleActeurStatutChange(stakeholder.statut.acteurId, 'present', !stakeholder.statut.present)}
            disabled={disabled}
          >
            Présent
          </Stakeholder.Chip>
          <Stakeholder.Chip
            variant={stakeholder.statut.represente ? 'contained' : 'outlined'}
            onClick={() => handleActeurStatutChange(stakeholder.statut.acteurId, 'represente', !stakeholder.statut.represente)}
            disabled={disabled}
          >
            Représentant
          </Stakeholder.Chip>
        </Stakeholder.ChipContainer>
        <Stakeholder.MoreDotsButton
          variant="text"
          size="small"
        >
          <Stakeholder.MoreDots onClick={handleMenuClick} />
        </Stakeholder.MoreDotsButton>
        <Menu
          anchorEl={anchorMenu}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEdit}>
            {`Voir le détail `}
            {isStakeholderUuid(stakeholder.intervenant.id || '') && '/ Modifier'}
          </MenuItem>
          <MenuItem
            onClick={() => setDeleteStakeholder(stakeholder.intervenant.nom)}
            disabled={disabled || !stakeholder.intervenant.canBeDeleted}
          >
            Supprimer
          </MenuItem>
        </Menu>
      </Stakeholder.GridContainer>
      {
        stakeholder.statut.represente &&
          <Stakeholder.RepresenteParContainer>
            <FormBoldTitle>
              Nom du représentant
            </FormBoldTitle>
            <TextField
              placeholder="Nom prénom"
              value={stakeholder.statut.representePar || ''}
              onChange={(e): void => handleActeurStatutChange(stakeholder.statut.acteurId, 'representePar', e.target.value)}
              disabled={disabled}
            />
          </Stakeholder.RepresenteParContainer>
      }
      {
        deleteStakeholder &&
          <StakeholderPersonDeleteModal
            handleClose={handleStakeholderDelete}
            name={deleteStakeholder}
            recourses={recourses}
          />
      }
    </div>
  )
}

export default StakeholdersPersonStakeholderLine
