/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import ImageHoverContainer from './ImageHoverContainer'
import VignetteRenderer from './VignetteRenderer'

/* Styled components -------------------------------------------------------- */
const FileName = styled.div`
  font-size: .8rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align-last: center;
`

/* Component declaration ---------------------------------------------------- */
interface VignetteRendererWithFilenameOfflineProps {
  file: Blob;
  onClick: (file: Blob) => void;
  width: string;
  height: string;
  name?: string;
}

const VignetteRendererWithFilenameOffline: React.FC<VignetteRendererWithFilenameOfflineProps> = ({
  file,
  onClick,
  width,
  height,
  name,
}) => {

  return (
    <ImageHoverContainer onClick={() => onClick(file)}>
      <VignetteRenderer
        file={file as File}
        height={height}
        width={width}
      />
      <FileName>
        {name}
      </FileName>
    </ImageHoverContainer>
  )
}

export default VignetteRendererWithFilenameOffline
