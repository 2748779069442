/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useLocation,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import RouteTitle from 'routers/Router/RouteTitle'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import CaseTravelerTabButton from './CaseTravelerTabButton/CaseTravelerTabButton'

/* Type imports ------------------------------------------------------------- */
import type { Irsi } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const tabs = [
  { title: 'Intervenants', path: 'intervenants' },
  { title: 'Conformité', path: 'conformite-du-risque' },
  { title: 'Sinistre', path: 'sinistre' },
  { title: 'Dommages', path: 'dommages' },
  { title: 'Indemnisation', path: 'indemnisation' },
  { title: 'Recours', path: 'recours' },
  { title: 'Confidentiel', path: 'confidentiel' },
  { title: 'Règlement', path: 'reglement' },
  { title: 'IRSI', path: 'irsi' },
] as const

/* Component declaration ---------------------------------------------------- */
interface CaseTravelerProps {
  caseId?: string;
  irsiData: Irsi;
  isOnline: boolean;
}

const CaseTraveler: React.FC<CaseTravelerProps> = ({
  caseId,
  irsiData,
  isOnline,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [ title, setTitle ] = useState<string>('')

  const selected = pathname.split('/').find((path) => tabs.find((tab) => tab.path === path))

  useEffect(() => {
    const tab = document.getElementById(tabs.find((tab) => tab.path === selected)?.title || '')
    tab?.scrollIntoView(false)
    const newTabValue = tabs.findIndex((tab) => pathname.indexOf(tab.path) !== -1)
    if (tabs[newTabValue]?.title) {
      setTitle(`${caseId} - ${tabs[newTabValue].title}`)
    }
  }, [ pathname, selected ])

  const handleNavigate = (where: string): React.MouseEventHandler<HTMLButtonElement> => () => {
    navigate(`/dossiers/${caseId}/traveller/${where}`)
  }

  return (
    <ScrollableFiltersContainer>
      <RouteTitle title={title} />
      {
        tabs.map((tab) => (
          (tab.title !== 'IRSI' || irsiData !== undefined) &&
            <CaseTravelerTabButton
              key={tab.title}
              selected={selected === tab.path}
              title={tab.title}
              onClick={handleNavigate(tab.path)}
              disabled={tab.title === 'Règlement' || tab.title === 'IRSI' ? !isOnline : false}
            />
        ))
      }
    </ScrollableFiltersContainer>
  )
}

export default CaseTraveler
