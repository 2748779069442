/* Framework imports -------------------------------------------------------- */
import { Dexie } from 'dexie'

/* Type imports ------------------------------------------------------------- */
import type { EntityTable } from 'dexie'

interface OfflineCaseDocument {
  caseId: string;
  fileId: string;
  blob: Blob;
  blobThumbnail?: Blob;
  category?: string;
  name?: string;
  imported?: string;
}

const db = new Dexie('OfflineCaseDocumentsDatabase') as Dexie & {
  documents: EntityTable<OfflineCaseDocument, 'fileId'>;
}

db.version(1).stores({
  documents: 'fileId, caseId, blob, blobThumbnail, category, name, imported, [caseId+imported]',
})

export type { OfflineCaseDocument }
export { db }
