/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  useGetSinappsSolicitInterventionTypeListQuery,
  useGetSinappsSolicitPrestationListQuery,
  useGetSinappsSolicitInterventionSubtypeListQuery,
  useGetSinappsSolicitDamageNatureListQuery,
  useGetSinappsSolicitOperationNatureListQuery,
  useGetSinappsSolicitMandateEntityListQuery,
  useGetSinappsSolicitCriticiteListQuery,
  useGetSinappsSolicitUrgencyReasonsListQuery,
  useGetSinappsSolicitConventionListQuery,
  useGetSinappsSolicitIrsiTrancheListQuery,
  useGetSinappsSolicitTypeListQuery,
  useGetSinappsSolicitStepListQuery,
  useGetSinappsSolicitCorrectionReasonListQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import {
  TextField,
  Select,
} from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Loader from 'components/Loader/Loader'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { DestinataireSollicitation } from 'API/__generated__/Api'
import {
  TypeSollicitation,

  Criticite,
  TypeIntervention,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface QaSinappsSolicitStakeholderProps {
  handleChange: (type: string, value: string | boolean | DestinataireSollicitation) => void;
  typeSollicitation?: TypeSollicitation;
  destinataire?: DestinataireSollicitation;
  natureOperation?: string | null;
  estComplexe?: boolean;
  jePeuxIntervenir?: boolean;
  criticite?: string | null;
  detailConvention?: string | null;
  intervention?: string;
  convention?: string | null;
}

const QaSinappsSolicitStakeholder: React.FC<QaSinappsSolicitStakeholderProps> = ({
  handleChange,
  typeSollicitation,
  destinataire,
  natureOperation,
  estComplexe,
  jePeuxIntervenir,
  criticite,
  detailConvention,
  intervention,
  convention,
}) => {
  const { caseId = '' } = useParams<{caseId: string}>()

  const {
    currentData: allowedTypeList = [],
    isFetching: isFetchingAllowedTypeList,
  } = useGetSinappsSolicitTypeListQuery(caseId)
  const {
    currentData: interventionTypeList = [],
    isFetching: isFetchingInterventionTypeList,
  } = useGetSinappsSolicitInterventionTypeListQuery()
  const {
    currentData: prestationList = [],
    isFetching: isFetchingPrestationList,
  } = useGetSinappsSolicitPrestationListQuery()
  const {
    currentData: interventionSubTypeList = [],
    isFetching: isFetchingInterventionSubTypeList,
  } = useGetSinappsSolicitInterventionSubtypeListQuery()
  const {
    currentData: damageNatureList = [],
    isFetching: isFetchingDamageNatureList,
  } = useGetSinappsSolicitDamageNatureListQuery()
  const {
    currentData: operationNatureList = [],
    isFetching: isFetchingOperationNatureList,
  } = useGetSinappsSolicitOperationNatureListQuery()
  const {
    currentData: mandateEntityList = [],
    isFetching: isFetchingMandateEntityList,
  } = useGetSinappsSolicitMandateEntityListQuery()
  const {
    currentData: criticiteList = [],
    isFetching: isFetchingCriticiteList,
  } = useGetSinappsSolicitCriticiteListQuery()
  const {
    currentData: urgencyList = [],
    isFetching: isFetchingUrgencyList,
  } = useGetSinappsSolicitUrgencyReasonsListQuery()
  const {
    currentData: conventionList = [],
    isFetching: isFetchingConventionList,
  } = useGetSinappsSolicitConventionListQuery()
  const {
    currentData: trancheIrsiList = [],
    isFetching: isFetchingTrancheIrsiList,
  } = useGetSinappsSolicitIrsiTrancheListQuery()
  const {
    currentData: stepList = [],
    isFetching: isFetchingStepList,
  } = useGetSinappsSolicitStepListQuery(typeSollicitation || TypeSollicitation.DemandeClarification)
  const {
    currentData: correctionList = [],
    isFetching: isFetchingCorrectionList,
  } = useGetSinappsSolicitCorrectionReasonListQuery()

  const selectedSolicitationType = useMemo(() => {
    if (isFetchingAllowedTypeList) return null
    const selected = allowedTypeList.find((a) => a.typeSollicitation?.code === typeSollicitation)

    return selected
  }, [ typeSollicitation, isFetchingAllowedTypeList ])

  useEffect(() => {
    if (selectedSolicitationType && selectedSolicitationType.destinataireAutorises?.[0]?.typeDestinataire.code) {
      handleChange('destinataire', selectedSolicitationType.destinataireAutorises[0])
    }
  }, [ selectedSolicitationType ])

  const handleSelectedStakeholder = (value: string) => {
    const found = selectedSolicitationType?.destinataireAutorises?.find((dest) => dest.destinataire.code === value)

    if (found) {
      handleChange('destinataire', found)
    }
  }

  const allowedTypeOptions: SegmentedButtonOption<string>[] = allowedTypeList.map((value) => ({ value: value.typeSollicitation?.code || '', label: value.typeSollicitation?.libelle }))
  const operationNatureOptions: SegmentedButtonOption<string>[] = operationNatureList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const complexityOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]
  const mandateEntityOptions: SegmentedButtonOption<string>[] = mandateEntityList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const criticiteOptions: SegmentedButtonOption<string>[] = criticiteList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const trancheIrsiOptions: SegmentedButtonOption<string>[] = trancheIrsiList.map(({ code, libelle }) => ({ value: code, label: libelle }) )

  if (isFetchingAllowedTypeList ||
    isFetchingPrestationList ||
    isFetchingInterventionSubTypeList ||
    isFetchingDamageNatureList ||
    isFetchingOperationNatureList ||
    isFetchingMandateEntityList ||
    isFetchingCriticiteList ||
    isFetchingUrgencyList ||
    isFetchingConventionList ||
    isFetchingTrancheIrsiList ||
    isFetchingStepList ||
    isFetchingCorrectionList ||
    isFetchingInterventionTypeList) {
    return <Loader />
  }

  return (
    <div>
      <FormBoldTitle>
        Type de sollicitation à formuler
      </FormBoldTitle>
      <SegmentedButtons
        options={allowedTypeOptions}
        selectedOption={typeSollicitation}
        setSelectedOption={(newVal) => handleChange('typeSollicitation', newVal)}
      />
      <FormBoldTitle>
        Destinataire
      </FormBoldTitle>
      <SegmentedButtons
        options={selectedSolicitationType?.destinataireAutorises?.map((val) => ({ value: val.destinataire.code, label: val.destinataire.libelle })) || []}
        selectedOption={destinataire?.destinataire.code || ''}
        setSelectedOption={(newVal) => handleSelectedStakeholder(newVal)}
      />
      {
        typeSollicitation === TypeSollicitation.DemandeNouvelleIntervention &&
          <>
            <FormBoldTitle>
              Type de l'intervention
            </FormBoldTitle>
            <Field
              component={Select}
              name="solicitStakeholder.intervention"
              displayEmpty
            >
              <MenuItem
                value=""
                disabled
              >
                Sélectionner
              </MenuItem>
              {
                interventionTypeList.map((value) => (
                  <MenuItem
                    value={value.code}
                    key={value.code}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
            {
              (intervention === TypeIntervention.EAD || intervention === TypeIntervention.ESS) &&
                <>
                  <FormBoldTitle>
                    Complément de prestation
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="solicitStakeholder.complementPrestation"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      prestationList.map((value) => (
                        <MenuItem
                          value={value.code}
                          key={value.code}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                  <FormBoldTitle>
                    Nature des dommages
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="solicitStakeholder.natureDommages"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      damageNatureList.map((value) => (
                        <MenuItem
                          value={value.code}
                          key={value.code}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </>
            }
            {
              intervention === TypeIntervention.ReparationEnNature &&
                <>
                  <FormBoldTitle>
                    Sous-type
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="solicitStakeholder.sousTypeIntervention"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      interventionSubTypeList.map((value) => (
                        <MenuItem
                          value={value.code}
                          key={value.code}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </>
            }
            {
              intervention === TypeIntervention.RechercheDeFuite &&
                <>
                  <FormBoldTitle>
                    Nature de l’opération de la recherche de fuite
                  </FormBoldTitle>
                  <SegmentedButtons
                    options={operationNatureOptions}
                    selectedOption={natureOperation || ''}
                    setSelectedOption={(newVal) => handleChange('natureOperation', newVal)}
                  />
                </>
            }
            <FormBoldTitle>
              Complexité
            </FormBoldTitle>
            <SegmentedButtons
              options={complexityOptions}
              selectedOption={estComplexe}
              setSelectedOption={(newVal) => handleChange('estComplexe', newVal)}
            />
            <FormBoldTitle>
              Entité à mandater
            </FormBoldTitle>
            <SegmentedButtons
              options={mandateEntityOptions}
              selectedOption={jePeuxIntervenir ? 'jePeuxIntervenir' : 'autrePrestataire'}
              setSelectedOption={(newVal) => handleChange('jePeuxIntervenir', newVal === 'jePeuxIntervenir')}
            />
            <FormBoldTitle>
              Criticité
            </FormBoldTitle>
            <SegmentedButtons
              options={criticiteOptions}
              selectedOption={criticite || ''}
              setSelectedOption={(newVal) => handleChange('criticite', newVal)}
            />
            {
              criticite === Criticite.Urgente &&
                <>
                  <FormBoldTitle>
                    Urgence
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="solicitStakeholder.motifUrgence"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      urgencyList.map((value) => (
                        <MenuItem
                          value={value.code}
                          key={value.code}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </>
            }
            <FormBoldTitle>
              Convention applicable
            </FormBoldTitle>
            <Field
              component={Select}
              name="solicitStakeholder.convention"
              displayEmpty
            >
              <MenuItem
                value=""
                disabled
              >
                Sélectionner
              </MenuItem>
              {
                conventionList.map((value) => (
                  <MenuItem
                    value={value.code}
                    key={value.code}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
            {
              convention === 'Irsi' &&
                <>
                  <FormBoldTitle>
                    Tranche IRSI
                  </FormBoldTitle>
                  <SegmentedButtons
                    options={trancheIrsiOptions}
                    selectedOption={detailConvention || ''}
                    setSelectedOption={(newVal) => handleChange('detailConvention', newVal)}
                  />
                </>
            }
          </>
      }
      {
        (typeSollicitation === TypeSollicitation.DemandeClarification || typeSollicitation === TypeSollicitation.DemandeCorrection) &&
          <>
            <FormBoldTitle>
              Étape
            </FormBoldTitle>
            <Field
              component={Select}
              name="solicitStakeholder.etapeConcernee"
              displayEmpty
            >
              <MenuItem
                value=""
                disabled
              >
                Sélectionner
              </MenuItem>
              {
                stepList.map((value) => (
                  <MenuItem
                    value={value.code}
                    key={value.code}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
            {
              typeSollicitation === TypeSollicitation.DemandeCorrection && (
                <>
                  <FormBoldTitle>
                    Motif de correction
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="solicitStakeholder.motifCorrection"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      correctionList.map((value) => (
                        <MenuItem
                          value={value.motif.code}
                          key={value.motif.code}
                        >
                          {value.motif.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </>
              )
            }
          </>
      }
      <FormBoldTitle>
        Précision sur la sollicitation :
      </FormBoldTitle>
      <Field
        component={TextField}
        name="solicitStakeholder.commentaire"
        placeholder="Votre message"
        rows={3}
        multiline
      />
    </div>
  )
}

export default QaSinappsSolicitStakeholder
